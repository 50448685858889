<template>
  <div class="textstyle mt-4 mb-2">
    <h4>{{ separator_text }}</h4>
    <hr />
  </div>
</template>

<script>
export default {
  props: ["separator_text"],
};
</script>

<style lang="scss" scoped>
.textstyle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text-align: center;
  font-weight: bold; /* Making font bold */
  font-size: 18px; /* Changing font size */
}
h4 {
  text-transform: uppercase;
  background: #d67511;
  padding: 5px 30px;
  color: white;
  border-radius: 4px;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
hr {
  position: relative;
  transform: translateY(-46px);
  border: none;
  border-top: 6px dotted #d67511;
  width: 75%;
}
</style>
