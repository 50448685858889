<template>
  <div class="home">
    <div v-if="isFeaturedProjectAvailable">
      <neko-separator :separator_text="$t('FEATURED_WORKS')" />
    </div>
    <div v-else>
      <neko-separator :separator_text="$t('FEATURED_WORKS')" />
      <b-container class="my-grid">
        <skeleton-card v-for="skeleton in 3" :key="skeleton" />
      </b-container>
    </div>
    <b-container class="my-5">
      <div :class="projects.length <= 2 ? 'my-5 my-grid-2' : 'my-5 my-grid'">
        <project-card
          v-for="project in projects"
          :key="project.id"
          :name="project.project_name"
          :image="project.project_image_url"
          :description="project.project_description"
          :platform="project.project_platform"
          :platformData="platforms"
          :projectData="project"
        ></project-card>
      </div>
    </b-container>
  </div>
</template>

<script>
import ProjectCard from "@/components/ProjectCard.vue";
import NekoSeparator from "@/components/NekoSeparator.vue";
import SkeletonCard from "@/components/SkeletonCard.vue";

import proxy from "../services/proxy";

export default {
  name: "Home",
  components: {
    "project-card": ProjectCard,
    "skeleton-card": SkeletonCard,
    "neko-separator": NekoSeparator,
  },
  computed: {
    isFeaturedProjectAvailable() {
      return !(this.projects.length === 0);
    },
  },
  mounted() {
    this.fetchData();
    this.track();
  },
  data() {
    return {
      projects: [],
      platforms: [],
    };
  },
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: "/home",
      });
    },
    featuredProjectsOnly(projects) {
      let filteredProjects = [];
      projects.filter((value) => {
        if (value.project_featured === true) {
          filteredProjects.push(value);
        }
      });
      return filteredProjects;
    },
    async fetchData() {
      proxy
        .get("/api/platforms/", {})
        .then((resp) => {
          const platforms = resp?.data || null;
          if (platforms === null) {
            throw Error("Error: No Data: " + JSON.stringify(resp));
          }

          if (platforms.length === 0) {
            throw Error("No Platform data found");
          }

          this.platforms = platforms;
        })
        .catch((err) => {
          console.log("Error" + JSON.stringify(err));
          this.$gtag.exception({
            description: "Home page did not fetch data",
            fatal: false,
          });
        });
      proxy
        .get("/api/public/projects/", {})
        .then((resp) => {
          const projects = resp?.data || null;

          if (projects === null) {
            throw Error("Error: No Data: " + JSON.stringify(resp));
          }

          if (projects.length === 0) {
            throw Error("No Projects data found");
          }

          this.projects = this.featuredProjectsOnly(projects);
        })
        .catch((err) => {
          this.$gtag.exception({
            description: "Home page did not fetch data",
            fatal: false,
          });
          console.log("Error" + JSON.stringify(err));
        });
    },
  },
};
</script>

<style scoped>
.home {
  width: 100%;
}
.my-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.2rem;
}
.my-grid-2 {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.2rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .my-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .my-grid-2 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 1200px) {
  .my-grid-2 {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1.2rem;
  }
}
@media only screen and (max-width: 370px) {
  .my-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
  .my-grid-2 {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}
</style>
