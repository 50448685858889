import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    filter: "ALL",
  },
  getters: {
    getfilter(state) {
      return state.filter;
    },
  },
  mutations: {
    setCurrentFilter(state, value) {
      state.filter = value;
    },
  },
  actions: {
    setfilter({commit}, value) {
      commit("setCurrentFilter", value);
    },
  },

  modules: {},
});
