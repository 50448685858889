import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem("lang") || "en",
  fallbackLocale: "jp",
  messages: {
    en: {
      HELLO: "Hello",
      HOME: "HOME",
      SERVICES: "SERVICES",
      WORKS: "WORKS",
      ABOUT: "ABOUT",
      ABOUT_US: "ABOUT US",
      GET_IN_TOUCH: "Get in touch with us via :",
      FOLLOW_US: "FOLLOW US",
      SKILLS: "SKILLS",
      NO_RESULT_FOUND: "No result found",
      NEKOLOGIC_FOOTER_DESCRIPTION:
        "is a studio that focuses on the intersection of the digital and the real worlds. We focus on AR, IoT, Game-assisted learning and other interactive mediums.",
      PRIVACY_POLICY: "Privacy Policy",
      FEATURED_WORKS: "FEATURED WORKS",
      CLIENT_PROJECTS: "Client Projects",
      NEKO_LOGIC_PROJECTS: "Nekologic Projects",
      IN_DEVELOPMENT: "In Development",
      OTHER: "Other",
      CAREER: "Career",
      POSITION_OPEN: "Open Positions",
      POSITION_GENERAL: "General Positions",
      OUR_MEMBERS: "Our Members",
      WHAT_WE_DO: "WHAT WE DO",
      PLATFORMS: "PLATFORMS",
      PLATFORMS_TECHNOLOGY: "PLATFORMS | TECHNOLOGY",
      WHO_WE_WORK_WITH: "WHO WE WORK WITH",
      WHAT_WE_OFFER: "What We Offer",
      SERVICES_PARAGRAPH_1:
        "We are a highly experienced team of creative professionals based in Japan. We utilise state of the art technology, mixed with modern software production techniques and our deep passion for learning and development to develop not just our own products, but to also realise the goals of our clients.",
      SERVICES_PARAGRAPH_2:
        "We focus on the intersection between the real-world and the digital world, and our experiences have brought us into contact with technologies as diverse as augmented reality, virtual reality, blockchain and much more.",
      SERVICES_PARAGRAPH_3:
        "We have delivered successfully on over 50 software development projects with a number of technologies including VR, web, mobile and plenty more. We hire the best and most creative people in order to execute on your designs and projects. With a portfolio of client-work, as well as our own tools, product and technology, let us help you make your dreams become reality.",
      SERVICES_PARAGRAPH_4:
        "We work with a tight-knit network of high-grade creative professionals that include software developers, designers, artists, translators, musicians, photographers, film-makers and more. We can provide everything from resource for larger projects, through to complete solutions for your creative needs. Here’s a sample of some of the services we can provide:",
      SOFTWARE_DEVELOPMENT_AND_CONSULTING:
        "Full Stack Software Development and Consulting: ",
      SOFTWARE_DEVELOPMENT_AND_CONSULTING_TEXT:
        "From concept to maintenance and every step in-between, we can prototype, scope, plan, produce, test and support you along every stage of the development process.",
      RESEARCH_AND_DEVELOPMENT: "Research and Development: ",
      RESEARCH_AND_DEVELOPMENT_TEXT:
        "Tap into our innovative and curious mindsets to facilitate the exploration of future improvements, services and products.",
      DESIGN_WEB_MOBILE: "User Interface Design: ",
      DESIGN_WEB_MOBILE_TEXT:
        "Utilise our designers to help you with any design scenario you may have. Have an idea and need it fleshed out into a product? We have you covered. Need to improve the aesthetics of an existing design? Send us a message.",
      GAME_DESIGN: "Game design: ",
      GAME_DESIGN_TEXT:
        "We have deep experience in the creation of games and the unique requirements they have. Let us help you bring your game ideas from conception through to execution.",
      ASSET_AND_CONTENTS_CREATION: "Creative Content Creation: ",
      ASSET_AND_CONTENTS_CREATION_TEXT:
        "Tap into our extensive network of musicians, audio-engineers, artists, film-makers, photographers and designers to help realise your contents and asset needs.\n\n2D/3D Art and Animation, Music, Sound Design, Photo/Video, 360° Content,etc.",
      TRANSLATION_AND_PROOFREADING: "Translation and Proofreading: ",
      TRANSLATION_AND_PROOFREADING_TEXT:
        "We have a wide network of translators, proof-readers, copy-writers and interpreters to help with your translation and communication needs.",
      CONSULTING: "Consulting: ",
      CONSULTING_TEXT:
        "Tap into our creative, development and business experience to help your projects and business.",
      INSTALLATIONS: "Installations and AR / VR / MR Experiences: ",
      INSTALLATIONS_TEXT:
        "Take your events, apps, and software to the next level with the latest augmented and virtual reality technology we expertly craft for your projects.",
      DONT_HESITATE:
        "Don't hesitate to contact us to help you with your business goals!",
      ABOUT_US_PARAGRAPH_1:
        "NekoLogic is a cutting-edge software development company based in Kyoto, Japan.",
      ABOUT_US_PARAGRAPH_2:
        "We combine a high level of development skill, with conscientious staff, deep technical experience, a positive international environment, and modern, up-to-date development processes to build great software for our clients and ourselves.",
      GAMES: "Games",
      GAMES_TEXT:
        "We believe in the power of game mechanics and their capacity to motivate and compel players. We believe we can delight at the same time as educate and can make learning as fun as the most compelling video game",
      LEARNING: "Learning",
      LEARNING_TEXT:
        "We believe cutting-edge technologies will change how we interact with the real-world and each other. We develop fun and interactive products that bridge this world with the digital and allow the learning of real-life skills",
      CRAFT: "Craft",
      CRAFT_TEXT:
        "We believe in pushing ourselves and our craft and utilise the best in modern development practices and agile production techniques. We have experience with mobile, VR, blockchain, AR, IoT, web and much more",
      NEKOTOWER: "A view of Neko-Towers, where all the magic happens!",
      THIS_COULD_BE_YOU: "THIS COULD BE YOU! ",
      THIS_COULD_BE_YOU_TEXT:
        "We are always on the lookout for talented, self-starting individuals. We work with a number of cool technologies and with a number of exciting clients. Get in touch with us if you are interested!",
      ABOUT_PAGE_PARAGRAPH_3:
        "a positive International environment and modern, up-to-date development processes to build great software for our clients and ourselves",
      WHAT_WE_BELIEVE_IN: "What We Believe In",
      SUSTAINABLE_DEVELOPMENT: "SUSTAINABLE DEVELOPMENT",
      SUSTAINABLE_DEVELOPMENT_TEXT:
        "We believe in marathons, not sprints. The world is changed one step at a time.\n We set high, yet attainable and sustainable goals to work towards a better future.",
      SELF_MANAGEMENT: "SELF-MANAGEMENT",
      SELF_MANAGEMENT_TEXT:
        "We care for what we do and take ownership.\n We believe that self-motivated, conscientious people do great things together.",
      CONSIDERATION_FOR_ALL: "CONSIDERATION FOR ALL",
      CONSIDERATION_FOR_ALL_TEXT:
        "Consideration for the customer, consideration for the team, consideration for society.\n We strive to understand stakeholders, even non-obvious ones, and act in the best interest of all.",
      CUTTING_EDGE_CRAFT: "CUTTING-EDGE CRAFT",
      CUTTING_EDGE_CRAFT_TEXT:
        "All members passionately pursue continuous professional development giving us skill sets that are varied, sharp, and ready to tackle any challenge.",
      WHOLENESS: "WHOLENESS",
      WHOLENESS_TEXT:
        "We believe in fostering an environment that embraces each member as a complete human being with myriad skills and unique personalities to express.",
      EMERGENT_PURPOSE: "EMERGENT PURPOSE",
      EMERGENT_PURPOSE_TEXT:
        "We emphasise flexibility to the changing needs of the present. We aim to connect the needs of our customers with the ever-growing skillsets of our staff and evolve our companies purpose appropriately.",
      ADDRESS:
        "162-6-401 Jodoji Shinnyocho,\n Sakyo Ward, Kyoto,\n 606-8414, Japan",
      ADDRESS_UK:
        "37-45 Windsor Street,\n Liverpool, Merseyside,\n L8 1XE, United Kingdom",
      PRIVACY_POLICY_TITLE: "PRIVACY POLICY",
      PRIVACY_POLICY_PARAGRAPH_1:
        "Effective date: October 12, 2018\n\n合同会社NekoLogic (“us”, “we”, or “our”) operates the http://neko-logic.com website (the “Service”)\n\nThis page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.\n\nWe use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from http://neko-logic.com",
      INFORMATION_COLLECTION: "INFORMATION COLLECTION AND USE",
      INFORMATION_COLLECTION_PARAGRAPH_1:
        "We collect several different types of information for various purposes to provide and improve our Service to you",
      DATA_COLLECTED: "TYPES OF DATA COLLECTED",
      PERSONAL_DATA: "PERSONAL DATA",
      PERSONAL_DATA_PARAGRAPH_1:
        "While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:",
      PERSONAL_DATA_ITEM_1: "Email address",
      PERSONAL_DATA_ITEM_2: "Cookies and Usage Data",
      USAGE_DATA: "USAGE DATA",
      USAGE_DATA_PARAGRAPH_1:
        "We may also collect information how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data",
      TRACKING_DATA: "TRACKING & COOKIES DATA",
      TRACKING_DATA_PARAGRAPH_1:
        "We use cookies and similar tracking technologies to track the activity on our Service and hold certain information\n\nCookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service\n\nYou can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service\n\nExamples of Cookies we use:",
      TRACKING_DATA_ITEM_1_STRONG: "Session Cookies. ",
      TRACKING_DATA_ITEM_1: "We use Session Cookies to operate our Service.",
      TRACKING_DATA_ITEM_2_STRONG: "Preference Cookies. ",
      TRACKING_DATA_ITEM_2:
        "We use Preference Cookies to remember your preferences and various settings.",
      TRACKING_DATA_ITEM_3_STRONG: "Security Cookies. ",
      TRACKING_DATA_ITEM_3: "We use Security Cookies for security purposes.",
      USE_OF_DATA: "USE OF DATA",
      USE_OF_DATA_PARAGRAPH_1:
        "合同会社NekoLogic uses the collected data for various purposes:",
      USE_OF_DATA_ITEM_1: "To provide and maintain the Service",
      USE_OF_DATA_ITEM_2: "To notify you about changes to our Service",
      USE_OF_DATA_ITEM_3:
        "To allow you to participate in interactive features of our Service when you choose to do so",
      USE_OF_DATA_ITEM_4: "To provide customer care and support",
      USE_OF_DATA_ITEM_5:
        "To provide analysis or valuable information so that we can improve the Service",
      USE_OF_DATA_ITEM_6: "To monitor the usage of the Service",
      USE_OF_DATA_ITEM_7: "To detect, prevent and address technical issues",
      TRANSFER_OF_DATA: "TRANSFER OF DATA",
      TRANSFER_OF_DATA_PARAGRAPH_1:
        "Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction\n\nIf you are located outside Japan and choose to provide information to us, please note that we transfer the data, including Personal Data, to Japan and process it there\n\nYour consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer\n\n合同会社NekoLogic will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information",
      DISCLOSURE_OF_DATA: "DISCLOSURE OF DATA",
      LEGAL_REQUIREMENTS: "LEGAL REQUIREMENTS",
      LEGAL_REQUIREMENTS_PARGRAPH_1:
        "合同会社NekoLogic may disclose your Personal Data in the good faith belief that such action is necessary to:",
      LEGAL_REQUIREMENTS_ITEM_1: "To comply with a legal obligation",
      LEGAL_REQUIREMENTS_ITEM_2:
        "To protect and defend the rights or property of 合同会社NekoLogic",
      LEGAL_REQUIREMENTS_ITEM_3:
        "To prevent or investigate possible wrongdoing in connection with the Service",
      LEGAL_REQUIREMENTS_ITEM_4:
        "To protect the personal safety of users of the Service or the public",
      LEGAL_REQUIREMENTS_ITEM_5: "To protect against legal liability",
      SECURITY_OF_DATA: "SECURITY OF DATA",
      SECURITY_OF_DATA_PARAGRAPH_1:
        "The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security",
      SERVICE_PROVIDERS: "SERVICE PROVIDERS",
      SERVICE_PROVIDERS_PARAGRAPH_1:
        "We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used\n\nThese third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose",
      ANALYTICS: "ANALYTICS",
      ANALYTICS_PARAGRAPH_1:
        "We may use third-party Service Providers to monitor and analyze the use of our Service",
      ANALYTICS_PARAGRAPH_2:
        "For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: ",
      ANALYTICS_ITEM_1_STRONG: "Google Analytics ",
      ANALYTICS_ITEM_1:
        "Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising networkYou can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity",
      LINKS_TO_OTHER_SITES: "LINKS TO OTHER SITES",
      LINKS_TO_OTHER_SITES_PARAGRAPH_1:
        "Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit\n\nWe have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services",
      CHILDRENS_PRIVACY: "CHILDREN’S PRIVACY",
      CHILDRENS_PRIVACY_PARAGRAPH_1:
        "Our Service does not address anyone under the age of 18 (“Children”)\n\nWe do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers",
      CHANGES_TO_PRIVACY_POLICY: "CHANGES TO THIS PRIVACY POLICY",
      CHANGES_TO_PRIVACY_POLICY_PARAGRAPH_1:
        "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page\n\nWe will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy\n\nYou are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page",
      CONTACT_US: "CONTACT US",
      CONTACT_US_PARAGRAPH_1:
        "If you have any questions about this Privacy Policy, please contact us:",
      CONTACT_US_ITEM_1: "By email: liam@neko-logic.com",

      CAREER_P1:
        "Nekologic is always on the lookout for talented cats who wish to join our crew.",
      CAREER_P2:
        "Visa support (Corona dependant!), accommodation support, competitive salaries and the chance to live and work in the wonderful city of Kyoto are on offer to the right candidate. A remote start is possible for a self-motivated, remote-working savvy candidate with the right skills and attitude.",
      CAREER_P3:
        "We are still a very small company; our teams consist of curious, inquisitive and motivated members who wear many hats on a given day. The job descriptions below are loose descriptions of the general positions that we have. But more important than any of these specifics is a can-do attitude, desire for self-improvement and a tenacity to see projects through to the end. If you think you have what it takes to join us, drop us a line! We’d love to hear from you.",
      CAREER_P4: "Engineer",
      CAREER_P5:
        "Our engineers are the core of what we do. They design, develop, maintain and modify the software we build for ourselves and our clients. Innovative problem-solvers with excellent communication skills and an intellectually curious mindset, you will build software that delights us and our clients.",
      CAREER_P6: "The majority of our software is built in:",
      CAREER_P7: "Frontend",
      CAREER_P8: "Unity",
      CAREER_P9: "Vue.js",
      CAREER_P10: "React",
      CAREER_P11: "Backend",
      CAREER_P12: "Python",
      CAREER_P13: "C#/DotNet",
      CAREER_P14: "PHP",
      CAREER_P15:
        "However, we have also built products in C++, WebGL, Ruby On Rails and many more.",
      CAREER_P16:
        "Our projects range from desktop to web, VR applications to console games. We believe strongly in a technology and platform agnostic mindset.What is key is to build amazing, high quality products that delight and impress.",
      CAREER_P17: "Contact us",
      CAREER_P18: "Producer/Planner",
      CAREER_P19:
        "Our producers are the glue that helps bind our teams together. An excellent communicator and leader, you know how to keep things silky smooth between our clients and our teams, and have what it takes to keep the ship afloat. You have a background in all aspects of keeping a games company running smoothly and will do anything to remove any challenges in the way of making the best software we can for ourselves and our clients.",
      CAREER_P20: "You will be any or all of the following:",
      CAREER_P21:
        "A relationship builder both with internal dev teams and external partners",
      CAREER_P22: "Fluent in project-management tools and methodologies",
      CAREER_P23:
        "Have a marketing mindset understand who we build for, what they want and how to engage with them",
      CAREER_P24:
        "A hands-on leader able to get his hands dirty and muck in with any part of the development process",
      CAREER_P25: "Artist/Designer",
      CAREER_P26:
        "Our artists bring intuitive design and beautiful aesthetics to the things we make. They can take a seed of an idea in someone else's head, a piece of writing, a bit of music, a product idea, and they can bring it out into the world and give it shape and form. Equally comfortable within a game engine creating production-level assets, or outside working with concepts, pitch material, wireframes and so on, you are able to produce work that wows and motivates our staff and our clients.",
      CAREER_P27:
        "Build compelling pitch material to drive new projects and ideas forward",
      CAREER_P28:
        "Build beautiful wireframes with the end-user needs firmly in mind and deliver them in a way that makes the work of developers easy",
      CAREER_P29:
        "Concept ideas from the smallest kernel of an idea, and give it shape and form, communicating with others to bring life to their ideas",
      CAREER_P30:
        "Build game assets including characters, UI, environments and anything else needed to build a game",
      CAREER_P31:
        "If any of the above sounds like you, contact us with an introduction, portfolio, CV and covering letter.We are looking forward to hearing from you!",
      CAREER_P32:
        "We welcome those with agile attitudes, child-like curiosity and an unquenchable desire for ongoing growth and learning.",
      CAREER_P33: "Please take a look at",
      CAREER_P33_2: "to get an overview of our current team and past projects.",
      CAREER_P34:
        "We believe that everyone has their own way of working, the times that work best for them and we believe in trusting our employees to make sensible decisions about their own self-management.",
      CAREER_P35:
        "Once plugged into our workflows and processes, remote work is an option and you be are free to work to a schedule that works best for you.",
      CAREER_P36:
        "Nekologic is an equal opportunities company with a diverse, inclusive team made up of several countries and a multitude of backgrounds. We welcome all nationalities and identities and we believe in the creation of spaces to foster candid discussion, reduce the fear of failure, and provide an environment where true collaboration can thrive. We respect each other's differences, we do not tolerate discrimination and we believe in including and welcoming everyone. We welcome and embrace the challenges that this represents and are focused on making a world-class organisation that welcomes talent from all backgrounds.",

      CAREER_P37:
        "These are the specific roles that we have open now on active projects. Please apply, giving the title that you’re interested in at",
      CAREER_P38: "this address",
      CAREER_P39_HEADER: "Experienced Project Manager:",
      CAREER_P39_LIST_1:
        "Native Japanese skills, intermediate to high English skills",
      CAREER_P39_LIST_2: "Work on and with large Flutter / Dart projects",
      CAREER_P39_LIST_3:
        "Support technical communications across Japanese projects, capable of working in a complex, multi-vendor Japanese work environment",
      CAREER_P39_LIST_4: "Desire to operate in an international environment",
      CAREER_P39_LIST_5:
        "Please note that this role involves a large amount of client-facing work and thus would be Tokyo-based",
      CAREER_P40_HEADER: "Flutter Engineer (X2):",
      CAREER_P40_LIST_1:
        "Work on large Flutter / Dart projects building UI components",
      CAREER_P40_LIST_2:
        "English speaking skills (you will be working in a predominantly English speaking team)",
      CAREER_P40_LIST_3:
        "A background in Design System / Component Design Methodologies desirable",
      CAREER_P40_LIST_4: "Flexible and able to adapt to new tasks quickly",
      CAREER_P40_LIST_5: "Remote OK. Overseas candidates welcome.",
      CAREER_P41_HEADER: "Sr. UI/UX Designer:",
      CAREER_P41_LIST_1:
        "Experience designing consumer-facing mobile apps using design systems",
      CAREER_P41_LIST_2:
        "High proficiency in Figma and, ideally, familiarity with design tokens",
      CAREER_P41_LIST_3:
        "High attention to detail, design patterns. typography and up-to-date with latest design trends",
      CAREER_P42: "General Positions",
      CAREER_P43:
        "We are always on the lookout for curious, inquisitive and talented people to join our team. If you feel you fulfil one of the below roles then please reach out and get in touch",

      CAREER_DIVERSITY: "Our Equality, Diversity and Inclusion Policy",
      COMMERCIAL_DISCLOSURE: "Commercial Disclosure",
      SCTA_COMMERCIAL_DISCLOSURE: "Commercial Disclosure",
      SCTA_DESCRIPTION: "",
      SCTA_LEGAL_NAME: "Legal Name",
      SCTA_LEGAL_NAME_A: "NekoLogic",
      SCTA_ADDRESS: "Address",
      SCTA_ADDRESS_A:
        "162-6-401 Jodoji Shinnyocho, Sakyo Ward, Kyoto, 606-8414",
      SCTA_PHONE_NUMBER: "Phone Number",
      SCTA_PHONE_NUMBER_A: "080-7643-3587",
      SCTA_EMAIL_ADDRESS: "Email Address",
      SCTA_EMAIL_ADDRESS_A: "liam@neko-logic.com",
      SCTA_HEAD_OF_OPERATIONS: "Head of operations",
      SCTA_HEAD_OF_OPERATIONS_A: "Liam Conroy",
      SCTA_ADDIONAL_FEES: "Additional Fees",
      SCTA_ADDIONAL_FEES_A:
        "None or as stipulated and agreed to by contract. Credit card transactions may incur an additional 4% fee as agreed upon in the contract. The customer is responsible for any internet connection charges, communication charges, server usage charges for product use, license charges for third-party products, eg. content purchases, software purchase, downloadable content and such.",
      SCTA_EXCHANGES_AND_RETURNS_POLICY: "Exchanges and returns policy",
      SCTA_EXCHANGES_AND_RETURNS_POLICY_A:
        "For any discrepencies or concerns after delivery and payment, please contact customer support: liam@neko-logic.com, this will vary from project to project.",
      SCTA_DELIVERY_TIMES: "Delivery Times",
      SCTA_DELIVERY_TIMES_A:
        "N/A, immediate, continual and or by contractual agreement.",
      SCTA_ACCEPTED_PAYMENT_METHODS: "Accepted Payment Methods",
      SCTA_ACCEPTED_PAYMENT_METHODS_A: "Direct Bank transfer, Credit Card.",
      SCTA_PAYMENT_PERIOD: "Payment Period",
      SCTA_PAYMENT_PERIOD_A:
        "Payments are expected as stipulated under the DUE DATE section in the sent invoice.",
      SCTA_PRICE: "Price",
      SCTA_PRICE_A: "Price is dependent on as per agreed contract.",
    },
    jp: {
      HELLO: "こんにちは",
      HOME: "HOME",
      SERVICES: "SERVICES",
      WORKS: "WORKS",
      ABOUT: "ABOUT",
      ABOUT_US: "About Us",
      GET_IN_TOUCH: "経由で私たちと連絡を取る",
      FOLLOW_US: "Follow Us",
      SKILLS: "スキル",
      NO_RESULT_FOUND: "結果なし",
      NEKOLOGIC_FOOTER_DESCRIPTION:
        "は京都を拠点として現実世界とデジタル世界を結ぶことに挑み続けている開発会社です。AR、VR、IoT、ゲーム開発、その他インタラクティブなメディアによる開発を行っています。",
      PRIVACY_POLICY: "プライバシーポリシー",
      FEATURED_WORKS: "Pick Up!",
      CLIENT_PROJECTS: "Client Works",
      NEKO_LOGIC_PROJECTS: "Nekologic Works ",
      IN_DEVELOPMENT: "In Development",
      OTHER: "その他",
      CAREER: "Career",
      POSITIONS: "Positions",
      POSITION_OPEN: "Open Positions",
      POSITION_GENERAL: "General Positions",
      OUR_MEMBERS: "Members",
      WHAT_WE_DO: "Our Services",
      PLATFORMS: "プラットフォーム ",
      PLATFORMS_TECHNOLOGY: "Our Technology ",
      WHO_WE_WORK_WITH: "取引先一覧",
      WHAT_WE_OFFER: "私たちが提供するもの",
      SERVICES_PARAGRAPH_1:
        "NEKOLOGICは京都を拠点として活動するクリエイティブ集団です。最新のソフトウェア開発技術を駆使して、自社製品の開発、及びクライアントの皆様の理想を実現します。",
      SERVICES_PARAGRAPH_2:
        "これまでに現実世界とデジタル世界を結ぶことに挑戦し続け、AR（拡張現実）、VR（仮想現実）、ブロックチェーンといった最新のテクノロジーの活用、また、ソフトウェア開発者やデザイナー、アーティストなど様々な人々とタッグを組むことで、50以上のソフトウェアを開発してきました。",
      SERVICES_PARAGRAPH_3:
        "大規模プロジェクト向けのリソースから、クリエイティブなニーズに対応するソリューションまで、様々な要望にお応えし、ご提案をさせていただきます。",
      SERVICES_PARAGRAPH_4:
        "以下は、私たちが提供するサービスの概要となります。",
      SOFTWARE_DEVELOPMENT_AND_CONSULTING:
        "ソフトウェア開発およびコンサルティング：",
      SOFTWARE_DEVELOPMENT_AND_CONSULTING_TEXT:
        "立案からメンテナンス、開発プロセスのあらゆる段階における、プロトタイプの作成、スコープの設定、計画、作成、テスト、サポートを行います。",
      RESEARCH_AND_DEVELOPMENT: "リサーチおよび開発：",
      RESEARCH_AND_DEVELOPMENT_TEXT:
        "革新的で独創的な発想を活かし、将来的な改善、サービス、製品の実現を行います。",
      DESIGN_WEB_MOBILE: "デザイン（Web、モバイル）：",
      DESIGN_WEB_MOBILE_TEXT:
        "製品化させたいと思っているアイデアはありますか？今考えているデザインをさらにブラッシュアップさせたいとお思いですか？お持ちのアイデアの設計、デザインの向上をお手伝いします。",
      GAME_DESIGN: "ゲームデザイン: ",
      GAME_DESIGN_TEXT:
        "ゲーム制作に関する豊富な経験を活かし、クライアントの皆様のアイデアを構想から実行に移すお手伝いをします。",
      ASSET_AND_CONTENTS_CREATION:
        "コンテンツ制作（2d、3d、音楽、写真、360°写真、映像、360°映像）： ",
      ASSET_AND_CONTENTS_CREATION_TEXT:
        "様々なクリエイターとの幅広いネットワークを活用し、コンテンツ制作の要望にお応えします。",
      TRANSLATION_AND_PROOFREADING: "翻訳、校正サービス： ",
      TRANSLATION_AND_PROOFREADING_TEXT:
        "翻訳者、校正者、および通訳者との幅広いネットワークを用いて、様々な媒体での翻訳、校正などのご要望にお応えします。",
      CONSULTING: "コンサルティング： ",
      CONSULTING_TEXT:
        "多種多様な開発支援の経験を活かして、プロジェクトやビジネスをお手伝いします。",
      INSTALLATIONS: "インスタレーション & AR / VR / MR : ",
      INSTALLATIONS_TEXT:
        "最新の拡張現実技術を用いて、イベント、アプリ、ソフトウェアのさらなるレベルアップをご提案させていただきます。",
      DONT_HESITATE: "ビジネスに限界はありません！気兼ねなくご相談ください！！",
      ABOUT_US_PARAGRAPH_1:
        "Nekologicは、日本の京都を拠点とする最先端のソフトウェア開発会社です。",
      ABOUT_US_PARAGRAPH_2:
        "私たちは、高レベルの開発スキルと、誠実なスタッフ、深い技術経験、確かな国際環境、最新の開発プロセスを組み合わせて、クライアントと私たち自身のための優れたソフトウェアを構築します。",
      GAMES: "Games",
      GAMES_TEXT:
        "ゲームは人々を惹きつけ、突き動かす大きな力を持っていると信じています。\n\n ゲームの力を通じて、教育や学習をさらに楽しむことができるものにしたいと考えております。",
      LEARNING: "Learning",
      LEARNING_TEXT:
        "最先端の技術によって人々が関わり合う方法を新しく変えることができると信じています。\n\n デジタルと現実世界をつなぎ、実生活での学習を促す楽しいプロダクトを開発していきます。",
      CRAFT: "Craft",
      CRAFT_TEXT:
        "アジャイル型開発により、スマートフォンやウェブ、VR、AR、IoT、ブロックチェーンに関する豊富な開発経験が私たちにはあります。\n\n これらの経験を活かし、日常を豊かにするお手伝いをしていきます。",
      NEKOTOWER: "全ての魔法が起こるNEKOLOGICタワーの様子！",
      THIS_COULD_BE_YOU: "ここに掲載されるのはあなたかも？ ",
      THIS_COULD_BE_YOU_TEXT:
        "才能ある開発者、クリエイターを募集しています。魅力的なテクノロジー、仲間とともに働くことに興味があれば、ぜひ連絡してね！",
      ABOUT_PAGE_PARAGRAPH_1:
        "NEKOLOGICは、京都に拠点を置くソフトウェア開発会社です。",
      ABOUT_PAGE_PARAGRAPH_2:
        "高いレベルの開発技術、良心的なスタッフ、深い技術的経験、",
      ABOUT_PAGE_PARAGRAPH_3:
        "前向きな国際的環境、最新の開発プロセスを組み合わせて、ソフトウェアを構築しています。",
      WHAT_WE_BELIEVE_IN: "企業理念",
      SUSTAINABLE_DEVELOPMENT: "持続可能でポジティブな開発",
      SUSTAINABLE_DEVELOPMENT_TEXT:
        "私たちは、短距離走ではなくマラソンを信じています。世界は一歩ずつ変わっていくものです。より良い未来に向けて、達成可能で持続可能な目標を設定します。",
      SELF_MANAGEMENT: "セルフマネジメント",
      SELF_MANAGEMENT_TEXT:
        "自分たちの行いを大切にし、責任を持っています。私たちが信じているのは、善良で自発的に行動できる人々が集まると、共に素晴らしいことが実現できるということです。",
      CONSIDERATION_FOR_ALL: "すべてに対する配慮",
      CONSIDERATION_FOR_ALL_TEXT:
        "お客さまへの配慮、チームへの配慮、社会への配慮。目に見えないステークホルダーも含め、すべての人々の利益となる行動をするよう努めます。",
      CUTTING_EDGE_CRAFT: "最先端の技術",
      CUTTING_EDGE_CRAFT_TEXT:
        "メンバー全員が情熱を持って、日々、専門性の向上に取り組んでおり、多様なスキルを持ち、どんな課題にも取り組むことができます。",
      WHOLENESS: "全体性（ホールネス）",
      WHOLENESS_TEXT:
        "ひとりひとりが、より幸せで充足した職場生活を送れることができる環境作りをするよう努めます。",
      EMERGENT_PURPOSE: "創発目標",
      EMERGENT_PURPOSE_TEXT:
        "臨機応変に現状のベストを尽くす柔軟な企業戦略に重きを置いています。社員一人ひとりの専門分野がお客さまのニーズや会社のゴールにつながることを目指しています。",
      ADDRESS: "〒 606-8414\n京都市左京区浄土寺真如町１６２−６\n真如堂アビタシオン４０１",
      ADDRESS_UK:
        "37-45, Windsor Street,\n Liverpool, Merseyside,\n United Kingdom, L8 1XE",
      PRIVACY_POLICY_TITLE: "PRIVACY POLICY JP",
      PRIVACY_POLICY_PARAGRAPH_1:
        "Effective date: October 12, 2018\n\n合同会社NekoLogic (“us”, “we”, or “our”) operates the http://neko-logic.com website (the “Service”)\n\nThis page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.\n\nWe use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from http://neko-logic.com",
      INFORMATION_COLLECTION: "INFORMATION COLLECTION AND USE",
      INFORMATION_COLLECTION_PARAGRAPH_1:
        "We collect several different types of information for various purposes to provide and improve our Service to you",
      DATA_COLLECTED: "TYPES OF DATA COLLECTED",
      PERSONAL_DATA: "PERSONAL DATA",
      PERSONAL_DATA_PARAGRAPH_1:
        "While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:",
      PERSONAL_DATA_ITEM_1: "Email address",
      PERSONAL_DATA_ITEM_2: "Cookies and Usage Data",
      USAGE_DATA: "USAGE DATA",
      USAGE_DATA_PARAGRAPH_1:
        "We may also collect information how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data",
      TRACKING_DATA: "TRACKING & COOKIES DATA",
      TRACKING_DATA_PARAGRAPH_1:
        "We use cookies and similar tracking technologies to track the activity on our Service and hold certain information\n\nCookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service\n\nYou can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service\n\nExamples of Cookies we use:",
      TRACKING_DATA_ITEM_1_STRONG: "Session Cookies. ",
      TRACKING_DATA_ITEM_1: "We use Session Cookies to operate our Service.",
      TRACKING_DATA_ITEM_2_STRONG: "Preference Cookies. ",
      TRACKING_DATA_ITEM_2:
        "We use Preference Cookies to remember your preferences and various settings.",
      TRACKING_DATA_ITEM_3_STRONG: "Security Cookies. ",
      TRACKING_DATA_ITEM_3: "We use Security Cookies for security purposes.",
      USE_OF_DATA: "USE OF DATA",
      USE_OF_DATA_PARAGRAPH_1:
        "合同会社NekoLogic uses the collected data for various purposes:",
      USE_OF_DATA_ITEM_1: "To provide and maintain the Service",
      USE_OF_DATA_ITEM_2: "To notify you about changes to our Service",
      USE_OF_DATA_ITEM_3:
        "To allow you to participate in interactive features of our Service when you choose to do so",
      USE_OF_DATA_ITEM_4: "To provide customer care and support",
      USE_OF_DATA_ITEM_5:
        "To provide analysis or valuable information so that we can improve the Service",
      USE_OF_DATA_ITEM_6: "To monitor the usage of the Service",
      USE_OF_DATA_ITEM_7: "To detect, prevent and address technical issues",
      TRANSFER_OF_DATA: "TRANSFER OF DATA",
      TRANSFER_OF_DATA_PARAGRAPH_1:
        "Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction\n\nIf you are located outside Japan and choose to provide information to us, please note that we transfer the data, including Personal Data, to Japan and process it there\n\nYour consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer\n\n合同会社NekoLogic will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information",
      DISCLOSURE_OF_DATA: "DISCLOSURE OF DATA",
      LEGAL_REQUIREMENTS: "LEGAL REQUIREMENTS",
      LEGAL_REQUIREMENTS_PARGRAPH_1:
        "合同会社NekoLogic may disclose your Personal Data in the good faith belief that such action is necessary to:",
      LEGAL_REQUIREMENTS_ITEM_1: "To comply with a legal obligation",
      LEGAL_REQUIREMENTS_ITEM_2:
        "To protect and defend the rights or property of 合同会社NekoLogic",
      LEGAL_REQUIREMENTS_ITEM_3:
        "To prevent or investigate possible wrongdoing in connection with the Service",
      LEGAL_REQUIREMENTS_ITEM_4:
        "To protect the personal safety of users of the Service or the public",
      LEGAL_REQUIREMENTS_ITEM_5: "To protect against legal liability",
      SECURITY_OF_DATA: "SECURITY OF DATA",
      SECURITY_OF_DATA_PARAGRAPH_1:
        "The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security",
      SERVICE_PROVIDERS: "SERVICE PROVIDERS",
      SERVICE_PROVIDERS_PARAGRAPH_1:
        "We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used\n\nThese third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose",
      ANALYTICS: "ANALYTICS",
      ANALYTICS_PARAGRAPH_1:
        "We may use third-party Service Providers to monitor and analyze the use of our Service",
      ANALYTICS_PARAGRAPH_2:
        "For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: ",
      ANALYTICS_ITEM_1_STRONG: "Google Analytics ",
      ANALYTICS_ITEM_1:
        "Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising networkYou can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity",
      LINKS_TO_OTHER_SITES: "LINKS TO OTHER SITES",
      LINKS_TO_OTHER_SITES_PARAGRAPH_1:
        "Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit\n\nWe have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services",
      CHILDRENS_PRIVACY: "CHILDREN’S PRIVACY",
      CHILDRENS_PRIVACY_PARAGRAPH_1:
        "Our Service does not address anyone under the age of 18 (“Children”)\n\nWe do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers",
      CHANGES_TO_PRIVACY_POLICY: "CHANGES TO THIS PRIVACY POLICY",
      CHANGES_TO_PRIVACY_POLICY_PARAGRAPH_1:
        "当社は、随時、プライバシーポリシーを更新することがあります。このページに新しいプライバシーポリシーを掲載することで、変更をお知らせします。\n\n変更が有効になる前に、電子メールやサービス上の目立つ通知でお知らせし、このプライバシーポリシーの上部にある「有効日」を更新します。\n\n変更がないか、定期的にこのプライバシーポリシーを確認することをお勧めします。このプライバシーポリシーの変更は、このページに掲載された時点で有効になります。",
      CONTACT_US: "CONTACT US",
      CONTACT_US_PARAGRAPH_1:
        "このプライバシーポリシーについてご質問がある場合は、下記までお問い合わせください。",
      CONTACT_US_ITEM_1: "By email: liam@neko-logic.com",

      CAREER_P1:
        "Nekologic では、私たちのチームに参加したい才能ある猫を常に探しています。",
      CAREER_P2:
        "適切な候補者には、ビザのサポート、宿泊施設のサポート、競争力のある給与、そして素晴らしい京都の街で生活し働くチャンスが提供されます。適切なスキルと姿勢を持ち、自発的でリモートワークに精通した候補者には、リモートスタートが可能です。",
      CAREER_P3:
        "当社はまだ非常に小さな会社です。当社のチームは、好奇心旺盛で探究心があり、やる気のあるメンバーで構成されており、毎日さまざまな役割を担っています。以下の職務内容は、当社の一般的な職務を大まかに説明したものです。しかし、これらの詳細よりも重要なのは、やる気、自己改善への意欲、プロジェクトを最後までやり遂げる粘り強さです。当社に入社する資格があると思われる方は、ぜひご連絡ください。ご連絡をお待ちしています。",
      CAREER_P4: "エンジニア",
      CAREER_P5:
        "当社のエンジニアは、当社の業務の中核を担っています。エンジニアは、当社とお客様のために構築するソフトウェアの設計、開発、保守、修正を行います。優れたコミュニケーション能力と知的好奇心を持つ革新的な問題解決者であれば、当社とお客様に喜んでいただけるソフトウェアを構築できます。",
      CAREER_P6: "当社のソフトウェアの大部分は以下に組み込まれています。",
      CAREER_P7: "Frontend",
      CAREER_P8: "Unity",
      CAREER_P9: "Vue.js",
      CAREER_P10: "React",
      CAREER_P11: "Backend",
      CAREER_P12: "Python",
      CAREER_P13: "C#/DotNet",
      CAREER_P14: "PHP",
      CAREER_P15:
        "ただし、C++、WebGL、Ruby On Rails などでも製品を構築しています。",
      CAREER_P16:
        "私たちのプロジェクトは、デスクトップから Web、VR アプリケーションからコンソール ゲームまで多岐にわたります。私たちは、テクノロジーやプラットフォームにとらわれない考え方を強く信じています。重要なのは、喜びと感動を与える、驚くほど高品質な製品を構築することです。",
      CAREER_P17: "お問い合わせ",
      CAREER_P18: "プロデューサー/プランナー",
      CAREER_P19:
        "プロデューサーは、チームを結びつける接着剤のような存在です。優れたコミュニケーターでありリーダーであるあなたは、クライアントとチームの間で物事をスムーズに進める方法を熟知しており、会社をうまく運営するために必要な資質を備えています。ゲーム会社を円滑に運営するためのあらゆる側面について経験があり、私たち自身とクライアントのために最高のソフトウェアを作るために、あらゆる困難を取り除くために全力を尽くします。",
      CAREER_P20: "あなたは以下のいずれか、またはすべてに該当します:",
      CAREER_P21: "社内の開発チームと外部のパートナーの両方との関係構築者",
      CAREER_P22:
        "プロジェクト管理ツールと方法論に精通している プロジェクト管理ツールと方法論に精通している",
      CAREER_P23:
        "マーケティングの考え方を持ち、誰のために構築するのか、彼らが何を求めているのか、そして彼らとどのように関わるのかを理解する",
      CAREER_P24:
        "開発プロセスのあらゆる部分に自ら手を出して関与できる実践的なリーダー",
      CAREER_P25: "アーティスト/デザイナー",
      CAREER_P26:
        "当社のアーティストは、直感的なデザインと美しい美学を、私たちが作るものに取り入れています。彼らは、他の人の頭の中にあるアイデアの種、文章、音楽、製品のアイデアを取り上げ、それを世界に持ち出し、形にすることができます。ゲーム エンジン内で制作レベルのアセットを作成することも、コンセプト、ピッチ マテリアル、ワイヤーフレームなどを扱う外部の作業も同じように快適にこなせるため、当社のスタッフやクライアントを驚かせ、やる気を起こさせる作品を生み出すことができます。",
      CAREER_P27:
        "新しいプロジェクトやアイデアを推進するための説得力のあるプレゼンテーション資料を作成する",
      CAREER_P28:
        "エンドユーザーのニーズをしっかりと念頭に置いて美しいワイヤーフレームを作成し、開発者の作業を容易にする方法で提供します。",
      CAREER_P29:
        "アイデアの最小の核からアイデアを構想し、それを形にして、他の人とコミュニケーションを取りながらアイデアに命を吹き込みます。",
      CAREER_P30:
        "キャラクター、UI、環境など、ゲームを構築するために必要なものを含むゲームアセットを構築します",
      CAREER_P31:
        "上記のいずれかに当てはまる場合は、自己紹介、ポートフォリオ、履歴書、カバーレターを添えてご連絡ください。ご連絡をお待ちしております。",
      CAREER_P32:
        "私たちは、機敏な姿勢、子供のような好奇心、そして継続的な成長と学習への飽くなき欲求を持つ人を歓迎します。",
      CAREER_P33: "当社の現在のチームと過去のプロジェクトの概要については、",
      CAREER_P33_2: "こちらをご覧ください。",
      CAREER_P34:
        "私たちは、誰もが自分なりの働き方、自分にとって最も働きやすい時間を持っていると信じており、従業員が自分自身の自己管理について賢明な決定を下せることを信頼しています。",
      CAREER_P35:
        "当社のワークフローとプロセスに組み込まれると、リモートワークがオプションとなり、自分にとって最適なスケジュールに合わせて自由に働くことができます。",
      CAREER_P36:
        "Nekologic は、複数の国とさまざまな背景を持つ多様性に富んだ包括的なチームを持つ機会均等の会社です。当社は、あらゆる国籍とアイデンティティを歓迎し、率直な議論を促進し、失敗への恐怖を減らし、真のコラボレーションが繁栄できる環境を提供するためのスペースの創出を信じています。当社は、お互いの違いを尊重し、差別を容認せず、すべての人を受け入れ、歓迎することを信じています。当社は、これに伴う課題を歓迎し、受け入れ、あらゆる背景を持つ才能を歓迎する世界クラスの組織を作ることに重点を置いています。",
      CAREER_P37:
        "これらは現在進行中のプロジェクトで募集している特定の役職です。ご興味のある役職名を明記の上、この",
      CAREER_P38: "アドレスにご応募ください。",
      CAREER_P39_HEADER: "経験豊富なプロジェクトマネージャー:",
      CAREER_P39_LIST_1: "日本語スキルN1/N2、中級から上級レベルの英語スキル",
      CAREER_P39_LIST_2: "大規模な Flutter / Dart プロジェクトに取り組む",
      CAREER_P39_LIST_3:
        "日本のプロジェクト全体にわたる技術コミュニケーションをサポートし、複雑でマルチベンダーの日本の作業環境で作業できる能力",
      CAREER_P39_LIST_4: "国際的な環境で活動したいという希望",
      CAREER_P39_LIST_5:
        "この職務にはクライアント対応業務が多数含まれるため、東京を拠点とすることになります。",
      CAREER_P40_HEADER: "Flutter エンジニア (X2):",
      CAREER_P40_LIST_1:
        "UI コンポーネントを構築する大規模な Flutter / Dart プロジェクトに取り組む",
      CAREER_P40_LIST_2: "英語を話すスキル（主に英語を話すチームで働きます）",
      CAREER_P40_LIST_3:
        "デザインシステム/コンポーネントデザイン方法論のバックグラウンドが望ましい",
      CAREER_P40_LIST_4: "柔軟性があり、新しいタスクに素早く適応できる",
      CAREER_P40_LIST_5: "リモートOK。海外からの応募も歓迎。",
      CAREER_P41_HEADER: "シニアUI/UXデザイナー:",
      CAREER_P41_LIST_1:
        "デザインシステムを使用して消費者向けモバイルアプリを設計した経験",
      CAREER_P41_LIST_2:
        "Figma に高い熟練度があり、できればデザイントークンに精通していること",
      CAREER_P41_LIST_3:
        "細部へのこだわり、デザインパターン、タイポグラフィ、最新のデザイントレンド",
      CAREER_P42: "一般的なポジション",
      CAREER_P43:
        "私たちは、好奇心旺盛で探究心があり、才能のある人材を常に求めています。以下のいずれかの役割に当てはまると思われる場合は、ぜひご連絡ください",

      CAREER_DIVERSITY: "Our Equality, Diversity and Inclusion Policy",
      COMMERCIAL_DISCLOSURE: "特定商取引法に基づく表記（案）",
      SCTA_COMMERCIAL_DISCLOSURE: "特定商取引法に基づく表記（案）",
      SCTA_DESCRIPTION: "",
      SCTA_LEGAL_NAME: "販売事業者名",
      SCTA_LEGAL_NAME_A: "合同会社Nekologic",
      SCTA_ADDRESS: "住所",
      SCTA_ADDRESS_A: "〒606-8414\n京都市左京区浄土寺真如町１６２−６\n真如堂アビタシオン４０１",
      SCTA_PHONE_NUMBER: "電話番号",
      SCTA_PHONE_NUMBER_A: "問い合わせがあった場合には遅滞なく開示します",
      SCTA_EMAIL_ADDRESS: "電子メールアドレス",
      SCTA_EMAIL_ADDRESS_A: "liam@neko-logic.com",
      SCTA_HEAD_OF_OPERATIONS: "運営責任者",
      SCTA_HEAD_OF_OPERATIONS_A: "コンロイ・ウィリアム",
      SCTA_ADDIONAL_FEES: "販売価格以外でお客様に発生する金銭",
      SCTA_ADDIONAL_FEES_A:
        "当サイトのページの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金、製品使用にかかるサーバ利用料やサードパーティ製品のライセンス料等は、お客様のご負担となります。",
      SCTA_EXCHANGES_AND_RETURNS_POLICY: "商品引き渡し時期",
      SCTA_EXCHANGES_AND_RETURNS_POLICY_A: "開発製品によって異なります。",
      SCTA_DELIVERY_TIMES: "配達時間",
      SCTA_DELIVERY_TIMES_A:
        "該当なし、即時、継続的、または契約上の合意による。",
      SCTA_ACCEPTED_PAYMENT_METHODS: "支払い方法",
      SCTA_ACCEPTED_PAYMENT_METHODS_A: "銀行振込",
      SCTA_PAYMENT_PERIOD: "返済期間",
      SCTA_PAYMENT_PERIOD_A:
        "支払いは、送信された請求書の「期限」か「DUE DATE」セクションに記載されているとおりに行われる予定です。",
      SCTA_PRICE: "価格",
      SCTA_PRICE_A: "製品開発内容によって異なる",
      SCTA_OS_SOFTWARE: "ソフトウェアを使用するための動作環境",
      SCTA_OS_SOFTWARE_A: "製品開発内容によって異なる",
    },
    fr: {
      HELLO: "Bonjour",
      HOME: "ACCUEIL",
      SERVICES: "SERVICES",
      WORKS: "TRAVAUX",
      ABOUT: "À PROPOS",
      ABOUT_US: "À PROPOS DE NOUS",
      GET_IN_TOUCH: "Contactez-nous via : ",
      FOLLOW_US: "SUIVEZ-NOUS",
      SKILLS: "Compétences",
      NO_RESULT_FOUND: "Aucun résultat trouvé",
      NEKOLOGIC_FOOTER_DESCRIPTION:
        "est un studio qui se concentre sur l'interaction entre le numérique et le monde réel. Nous nous concentrons sur la RA, l'IoT, l'apprentissage assisté par le jeu et d'autres supports interactifs.",
      PRIVACY_POLICY: "Politique de confidentialité",
      FEATURED_WORKS: "Travaux en vedette",
      CLIENT_PROJECTS: "Projects Clients",
      NEKO_LOGIC_PROJECTS: "Projects de Nekologic",
      IN_DEVELOPMENT: "En développement",
      OTHER: "Autre",
      POSITIONS: "Postes",
      POSITION_OPEN: "Postes Vacants",
      POSITION_GENERAL: "Postes Généraux",
      CAREER: "carrière",
      OUR_MEMBERS: "Nos membres",
      WHAT_WE_DO: "Nos services",
      PLATFORMS: "PLATEFORMES",
      PLATFORMS_TECHNOLOGY: "PLATEFORMES | TECHNOLOGIES",
      WHO_WE_WORK_WITH: "AVEC QUI NOUS TRAVAILLONS",
      WHAT_WE_OFFER: "Nos offres",
      SERVICES_PARAGRAPH_1:
        "Nous sommes une équipe hautement expérimentée de professionnels de la création basée au Japon. Nous utilisons des technologies de pointe, associée à des techniques de production de logiciels modernes et notre profonde passion pour l'apprentissage et le développement, nous développons non seulement nos propres produits, mais également nous atteignons les objectifs de nos clients.",
      SERVICES_PARAGRAPH_2:
        "Nous nous concentrons sur l'intersection entre le monde réel et le monde numérique, nos expériences nous ont mis sur le chemin de technologies aussi diverses que la réalité augmentée, la réalité virtuelle, la blockchain et bien plus encore.",
      SERVICES_PARAGRAPH_3:
        "Nous avons réalisé avec succès plus de 50 projets de développement de logiciels avec un certain nombre de technologies, notamment la RV, le Web, le mobile et bien d'autres. Nous embauchons les meilleurs et les plus créatifs pour exécuter vos conceptions et projets. Avec un portefeuille de travail client, ainsi que nos propres outils, produits et technologies, laissez-nous vous aider à réaliser vos rêves.",
      SERVICES_PARAGRAPH_4:
        "Nous travaillons avec un réseau soudé de professionnels de la création de haut niveau qui comprend des développeurs de logiciels, des designers, des artistes, des traducteurs, des musiciens, des photographes, des cinéastes et plus encore. Nous pouvons tout fournir, des ressources pour des projets plus importants, aux solutions complètes pour vos besoins créatifs. Voici un échantillon des services que nous pouvons fournir:",
      SOFTWARE_DEVELOPMENT_AND_CONSULTING:
        "Développement de logiciels et Consulting: ",
      SOFTWARE_DEVELOPMENT_AND_CONSULTING_TEXT:
        "De la conception à la maintenance et à chaque étape intermédiaire, nous pouvons prototyper, étendre, planifier, produire, tester et vous accompagner à chaque étape du processus de développement.",
      RESEARCH_AND_DEVELOPMENT: "Recherche et développement: ",
      RESEARCH_AND_DEVELOPMENT_TEXT:
        "Tirez parti de nos mentalités novatrices et curieuses pour faciliter l'exploration des améliorations, services et produits futurs.",
      DESIGN_WEB_MOBILE: "Design d'interface utilisateur: ",
      DESIGN_WEB_MOBILE_TEXT:
        "Faites appel à nos designers pour vous aider avec n'importe quel scénario de conception que vous pourriez avoir. Vous avez une idée et vous en avez besoin pour en faire un produit? Nous vous avons couvert. Besoin d'améliorer l'esthétique d'un design existant? Envoyez-nous un message.",
      GAME_DESIGN: "Game design: ",
      GAME_DESIGN_TEXT:
        "Nous avons une grande expérience dans la création de jeux et de leurs exigences uniques. Laissez-nous vous aider à apporter vos idées de jeux de la conception à l'exécution.",
      ASSET_AND_CONTENTS_CREATION: "Création de contenus: ",
      ASSET_AND_CONTENTS_CREATION_TEXT:
        "Faites appel à notre vaste réseau de musiciens, d'ingénieurs du son, d'artistes, de cinéastes, de photographes et de designers pour vous aider à réaliser vos besoins en contenu et en assets.\n\n2D/3D Art et Animatin, Musique, Sound Design, Photo/Vidéo, contenu 360°, etc.",
      TRANSLATION_AND_PROOFREADING: "Traduction et relecture: ",
      TRANSLATION_AND_PROOFREADING_TEXT:
        "Nous disposons d'un vaste réseau de traducteurs, de relecteurs, de rédacteurs et d'interprètes pour répondre à vos besoins de traduction et de communication.",
      CONSULTING: "Consulting: ",
      CONSULTING_TEXT:
        "Tirez parti de notre expérience créative, de développement et d'affaires pour aider vos projets et votre entreprise.",
      INSTALLATIONS: "Installations et expériences AR / VR / MR:",
      INSTALLATIONS_TEXT:
        "Faites passer vos événements, applications et logiciels au niveau supérieur avec les dernières technologies de réalité augmentée et virtuelle que nous pouvons concevoir pour vos projets.",
      DONT_HESITATE:
        "N'hésitez pas à nous contacter pour vous aider dans vos objectifs commerciaux!",
      ABOUT_US_PARAGRAPH_1:
        "NekoLogic est une société de développement de logiciels de pointe basée à Kyoto, au Japon.",
      ABOUT_US_PARAGRAPH_2:
        "Nous combinons un haut niveau de compétences en développement, un personnel consciencieux, une expérience technique approfondie, un environnement international et des processus de développement modernes et à jour pour créer d'excellents logiciels pour nos clients et nous-mêmes.",
      GAMES: "Jeux",
      GAMES_TEXT:
        "Nous croyons au pouvoir des mécanismes du jeu et à leur capacité à motiver et à contraindre les joueurs. Nous croyons que nous pouvons à la fois ravir et éduquer et que nous pouvons rendre l'apprentissage aussi amusant que le jeu vidéo le plus fascinant",
      LEARNING: "Apprentissage",
      LEARNING_TEXT:
        "Nous pensons que les technologies de pointe vont changer notre façon d'interagir avec le monde réel. Nous développons des produits amusants et interactifs qui font le lien entre ce monde et le numérique et permettre l'apprentissage de réelles compétences de la vie",
      CRAFT: "Savoir-faire",
      CRAFT_TEXT:
        "Nous croyons en la nécessité de nous dépasser et de pousser notre savoir-faire et utilisons les meilleurs pratiques de développement modernes et techniques de production agiles. Nous avons de l'expérience avec le mobile, la VR, la chaîne de blocs, la AR, l'IoT, le web et bien d'autres choses encore.",
      NEKOTOWER: "Une vue sur la Neko-Tower, où toute la magie s'opère!",
      THIS_COULD_BE_YOU: "CECI POURRAIT ÊTRE VOUS! ",
      THIS_COULD_BE_YOU_TEXT:
        "Nous sommes toujours à la recherche d'individus talentueux et autonomes. Nous travaillons avec un certain nombre de technologies cool et avec un grand nombre de clients passionnants. Contactez-nous si vous êtes intéressé!",
      ABOUT_PAGE_PARAGRAPH_1:
        "Nekologic est une société de développement de logiciels de pointe basée à Kyoto, au Japon.",
      ABOUT_PAGE_PARAGRAPH_2:
        "Nous combinons un haut niveau d'expertise en développement, un personnel consciencieux, une expérience technique approfondie,",
      ABOUT_PAGE_PARAGRAPH_3:
        "un environnement international et des processus de développement modernes et à jour pour créer d'excellents logiciels pour nos clients et pour nous-mêmes.",
      WHAT_WE_BELIEVE_IN: "Ce en quoi nous croyons",
      SUSTAINABLE_DEVELOPMENT: "DÉVELOPPEMENT DURABLE ET POSITIF",
      SUSTAINABLE_DEVELOPMENT_TEXT:
        "Nous croyons aux marathons, pas aux sprints. Le monde change une étape à la fois.\n Nous nous fixons des objectifs ambitieux, mais réalisables et durables pour travailler vers un avenir meilleur.",
      SELF_MANAGEMENT: "L'AUTOGESTION",
      SELF_MANAGEMENT_TEXT:
        "Nous nous soucions de ce que nous faisons et ce dont nous prenons possession.\n Nous croyons que les personnes consciencieuses et motivées font de grandes choses ensemble.",
      CONSIDERATION_FOR_ALL: "CONSIDÉRATION POUR TOUS",
      CONSIDERATION_FOR_ALL_TEXT:
        "Pour le client, pour l'équipe et pour la société.\n Nous nous efforçons de comprendre les parties prenantes, même non évidentes, et agissons dans le meilleur intérêt de tous.",
      CUTTING_EDGE_CRAFT: "Réalisations DE POINTE",
      CUTTING_EDGE_CRAFT_TEXT:
        "Tous les membres poursuivent avec passion un développement professionnel continu en offrant des compétences variées, pointues et prêtes à relever tous les défis.",
      WHOLENESS: "INTÉGRITÉ",
      WHOLENESS_TEXT:
        "Nous croyons en la promotion d'un environnement complet et varié avec une myriade de compétences et de personnalités uniques.",
      EMERGENT_PURPOSE: "OBJECTIF ÉMERGENT",
      EMERGENT_PURPOSE_TEXT:
        "Nous pensons qu'il est bien plus précieux «d'écouter et de s'adapter» que de «prédire et contrôler».",
      ADDRESS:
        "162-6-401 Jodoji Shinnyocho,\n Sakyo Ward, Kyoto,\n 606-8414, Japon",
      ADDRESS_UK:
        "37-45, Windsor Street,\n Liverpool, Merseyside,\n Royaume-Uni, L8 1XE",
      PRIVACY_POLICY_TITLE: "POLITIQUE DE CONFIDENTIALITÉ",
      PRIVACY_POLICY_PARAGRAPH_1:
        "Date d'entrée en vigueur : 12 octobre 2018\n\n合同会社NekoLogic ('nous', 'notre' ou 'nos') exploite le site web http://neko-logic.com (le 'Service')\n\nCette page vous informe de nos politiques concernant la collecte, l'utilisation et la divulgation des données personnelles lorsque vous utilisez notre Service et des choix que vous avez associés à ces données.\n\nous utilisons vos données pour fournir et améliorer le Service. En utilisant le Service, vous acceptez la collecte et l'utilisation des informations conformément à cette politique. Sauf définition contraire dans la présente politique de confidentialité, les termes utilisés dans la présente politique de confidentialité ont la même signification que dans nos conditions générales, accessibles à l'adresse http://neko-logic.com",
      INFORMATION_COLLECTION: "COLLECTE ET UTILISATION DES INFORMATIONS",
      INFORMATION_COLLECTION_PARAGRAPH_1:
        "Nous collectons plusieurs types d'informations différentes à des fins diverses pour vous fournir et améliorer notre Service",
      DATA_COLLECTED: "TYPES DE DONNÉES COLLECTÉES",
      PERSONAL_DATA: "DONNÉES PERSONNELLES",
      PERSONAL_DATA_PARAGRAPH_1:
        "Lors de l'utilisation de notre Service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou vous identifier ('Données personnelles'). Les informations personnellement identifiables peuvent inclure, sans s'y limiter : ",
      PERSONAL_DATA_ITEM_1: "Adresse électronique",
      PERSONAL_DATA_ITEM_2: "Cookies et données d'utilisation",
      USAGE_DATA: "DONNÉES D'UTILISATION",
      USAGE_DATA_PARAGRAPH_1:
        "Nous pouvons également collecter des informations sur la manière dont le Service est consulté et utilisé ('Données d'utilisation'). Ces données d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet (par exemple l'adresse IP) de votre ordinateur, le type de navigateur, la version du navigateur, les pages de notre service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, les identifiants uniques des appareils et d'autres données de diagnostic",
      TRACKING_DATA: "DONNÉES DE SUIVI ET DE COOKIES",
      TRACKING_DATA_PARAGRAPH_1:
        "Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur notre Service et détenir certaines informations\n\nLes cookies sont des fichiers contenant une petite quantité de données qui peuvent inclure un identifiant unique anonyme. Les cookies sont envoyés à votre navigateur à partir d'un site web et stockés sur votre appareil. Les technologies de suivi également utilisées sont les balises, les marqueurs et les scripts pour collecter et suivre les informations et pour améliorer et analyser notre service. Toutefois, si vous n'acceptez pas les cookies, il se peut que vous ne puissiez pas utiliser certaines parties de notre Service",
      TRACKING_DATA_ITEM_1_STRONG: "Cookies de session. ",
      TRACKING_DATA_ITEM_1:
        "Nous utilisons des cookies de session pour faire fonctionner notre Service.",
      TRACKING_DATA_ITEM_2_STRONG: "Cookies de préférence. ",
      TRACKING_DATA_ITEM_2:
        "Nous utilisons des cookies de préférence pour mémoriser vos préférences et divers paramètres.",
      TRACKING_DATA_ITEM_3_STRONG: "Cookies de sécurité. ",
      TRACKING_DATA_ITEM_3:
        "Nous utilisons des cookies de sécurité à des fins de sécurité",
      USE_OF_DATA: "UTILISATION DES DONNÉES",
      USE_OF_DATA_PARAGRAPH_1:
        "合同会社NekoLogic utilise les données collectées à diverses fins :",
      USE_OF_DATA_ITEM_1: "Pour fournir et maintenir le Service",
      USE_OF_DATA_ITEM_2:
        "Pour vous informer des modifications apportées à notre Service",
      USE_OF_DATA_ITEM_3:
        "Pour vous permettre de participer à des fonctions interactives de notre Service lorsque vous choisissez de le faire",
      USE_OF_DATA_ITEM_4:
        "Pour fournir un service et une assistance à la clientèle",
      USE_OF_DATA_ARTICLE_5:
        "Pour fournir une analyse ou des informations précieuses afin que nous puissions améliorer le Service ",
      USE_OF_DATA_ITEM_6: "Pour surveiller l'utilisation du service",
      USE_OF_DATA_ITEM_7:
        "Détecter, prévenir et résoudre les problèmes techniques",
      TRANSFER_OF_DATA: "TRANSFERT DE DONNÉES",
      TRANSFER_OF_DATA_PARAGRAPH_1:
        "Vos informations, y compris les Données personnelles, peuvent être transférées vers - et conservées sur - des ordinateurs situés en dehors de votre état, province, pays ou autre juridiction gouvernementale où les lois de protection des données peuvent différer de celles de votre juridiction.\n\nSi vous êtes situé en dehors du Japon et choisissez de nous fournir des informations, veuillez noter que nous transférons les données, y compris les Données personnelles, vers le Japon et les traitons à cet endroit\n\n\nVotre consentement à la présente politique de confidentialité suivi de votre soumission de ces informations représente votre accord pour ce transfert\n\n\n合同会社NekoLogic prendra toutes les mesures raisonnablement nécessaires pour s'assurer que vos données sont traitées en toute sécurité et conformément à la présente politique de confidentialité et aucun transfert de vos données personnelles n'aura lieu vers une organisation ou un pays à moins qu'il n'existe des contrôles adéquats en place, y compris la sécurité de vos données et autres informations personnelles",
      DISCLOSURE_OF_DATA: "DIVULGATION DE DONNÉES",
      LEGAL_REQUIREMENTS: "EXIGENCES LÉGALES",
      LEGAL_REQUIREMENTS_PARGRAPH_1:
        "合同会↪LoHan_79393NekoLogic peut divulguer vos Données personnelles en croyant de bonne foi qu'une telle action est nécessaire pour :",
      LEGAL_REQUIREMENTS_ITEM_1: "Se conformer à une obligation légale",
      LEGAL_REQUIREMENTS_ITEM_2:
        "Protéger et défendre les droits ou les biens de 合同会社NekoLogic",
      LEGAL_REQUIREMENTS_ITEM_3:
        "Pour prévenir ou enquêter sur d'éventuels actes répréhensibles en rapport avec le Service",
      LEGAL_REQUIREMENTS_ITEM_4:
        "Pour protéger la sécurité personnelle des utilisateurs du Service ou du public",
      LEGAL_REQUIREMENTS_ITEM_5:
        "Pour se protéger contre la responsabilité légale",
      SECURITY_OF_DATA: "SÉCURITÉ DES DONNÉES",
      SECURITY_OF_DATA_PARAGRAPH_1:
        "La sécurité de vos données est importante pour nous, mais n'oubliez pas qu'aucune méthode de transmission sur Internet, ni aucune méthode de stockage électronique n'est sûre à 100%. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons pas garantir leur sécurité absolue",
      SERVICE_PROVIDERS: "FOURNISSEURS DE SERVICES",
      SERVICE_PROVIDERS_PARAGRAPH_1:
        "Nous pouvons employer des sociétés et des personnes tierces pour faciliter notre Service ('Prestataires de services'), pour fournir le Service en notre nom, pour effectuer des services liés au Service ou pour nous aider à analyser la façon dont notre Service est utilisé\n\nCes tiers ont accès à vos Données personnelles uniquement pour effectuer ces tâches en notre nom et sont tenus de ne pas les divulguer ou les utiliser à d'autres fins",
      ANALYTICS: "ANALYTICS",
      ANALYTICS_PARAGRAPH_1:
        "Nous pouvons faire appel à des fournisseurs de services tiers pour surveiller et analyser l'utilisation de notre service",
      ANALYTICS_PARAGRAPH_2:
        "Pour plus d'informations sur les pratiques de Google en matière de confidentialité, veuillez consulter la page Web des conditions et de la confidentialité de Google : ",
      ANALYTICS_ITEM_1_STRONG: "Google Analytics",
      ANALYTICS_ITEM_1:
        "Google Analytics est un service d'analyse Web proposé par Google qui permet de suivre et de signaler le trafic sur un site Web. Google utilise les données collectées pour suivre et contrôler l'utilisation de notre service. Ces données sont partagées avec d'autres services de Google. Google peut utiliser les données collectées pour contextualiser et personnaliser les annonces de son propre réseau publicitaire. Vous pouvez désactiver la mise à disposition de votre activité sur le Service à Google Analytics en installant le module complémentaire de navigateur pour la désactivation de Google Analytics. Ce module complémentaire empêche le JavaScript de Google Analytics (ga.js, analytics.js et dc.js) de partager avec Google Analytics des informations sur l'activité des visites",
      LINKS_TO_OTHER_SITES: "LIENS VERS D'AUTRES SITES",
      LINKS_TO_OTHER_SITES_PARAGRAPH_1:
        "Notre service peut contenir des liens vers d'autres sites qui ne sont pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site que vous visitez. Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout site ou service tiers",
      CHILDRENS_PRIVACY: "VIE PRIVÉE DES ENFANTS",
      CHILDRENS_PRIVACY_PARAGRAPH_1:
        "Notre Service ne s'adresse pas aux personnes âgées de moins de 18 ans ('Enfants')\n\nNous ne recueillons pas sciemment d'informations personnellement identifiables auprès de personnes âgées de moins de 18 ans. Si vous êtes un parent ou un tuteur et que vous savez que vos enfants nous ont fourni des données personnelles, veuillez nous contacter. Si nous nous rendons compte que nous avons collecté des données personnelles d'enfants sans vérification du consentement parental, nous prenons des mesures pour supprimer ces informations de nos serveurs",
      CHANGES_TO_PRIVACY_POLICY:
        "MODIFICATIONS APPORTÉES À CETTE POLITIQUE DE CONFIDENTIALITÉ",
      CHANGES_TO_PRIVACY_POLICY_PARAGRAPH_1:
        "Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page. Nous vous en informerons par e-mail et/ou par un avis bien visible sur notre Service, avant que le changement n'entre en vigueur et nous mettrons à jour la 'date d'entrée en vigueur' en haut de cette politique de confidentialité. Les modifications apportées à la présente politique de confidentialité entrent en vigueur lorsqu'elles sont publiées sur cette page",
      CONTACT_US: "CONTACTEZ-NOUS",
      CONTACT_US_PARAGRAPH_1:
        "Si vous avez des questions sur la présente politique de confidentialité, veuillez nous contacter :",
      CONTACT_US_ITEM_1: "Par courriel : liam@neko-logic.com",

      CAREER_P1:
        "Nekologic est toujours à la recherche de chats talentueux qui souhaitent rejoindre notre équipe.",
      CAREER_P2:
        "Nous offrons une aide à l'obtention d'un visa (dépendant de Corona !), une aide à l'hébergement, des salaires compétitifs et la possibilité de vivre et de travailler dans la merveilleuse ville de Kyoto sont proposés au bon candidat.Un départ à distance est possible pour un candidat motivé, qui sait travailler à distance et qui possède les bonnes compétences et la bonne attitude.",
      CAREER_P3:
        "Nous sommes encore une très petite entreprise ; nos équipes sont composées de membres curieux, investigateurs et motivés qui portent plusieurs chapeaux au cours d'une journée donnée. Les descriptions de poste ci-dessous sont des descriptions générales des postes que nous occupons, mais le plus important est une attitude positive, un désir de s'améliorer et une ténacité à mener les projets à terme. Si vous pensez avoir ce qu'il faut pour nous rejoindre, écrivez-nous ! Si vous pensez avoir ce qu'il faut pour nous rejoindre, envoyez-nous un message ! Nous serons ravis de vous répondre.",
      CAREER_P4: "Ingénieur",
      CAREER_P5:
        "Nos ingénieurs sont au cœur de ce que nous faisons. Ils conçoivent, développent, entretiennent et modifient les logiciels que nous créons pour nous-mêmes et pour nos clients. Résolveurs de problèmes innovants, dotés d'excellentes aptitudes à la communication et d'un esprit de curieux, vous créerez des logiciels qui nous raviront, ainsi que nos clients.",
      CAREER_P6: "La majorité de nos logiciels sont intégrés en:",
      CAREER_P7: "Frontend",
      CAREER_P8: "Unity",
      CAREER_P9: "Vue.js",
      CAREER_P10: "React",
      CAREER_P11: "Backend",
      CAREER_P12: "Python",
      CAREER_P13: "C#/DotNet",
      CAREER_P14: "PHP",
      CAREER_P15:
        "Cependant, nous avons également construit des développé en C++, WebGL, Ruby On Rails et bien d'autres encore.",
      CAREER_P16:
        "Nos projets vont de l'application bureau au web, des applications VR aux jeux sur console. Nous croyons fermement en un état d'esprit agnostique vis-à-vis de la technologie et des plateformes. L'essentiel est de construire des produits étonnants et de haute qualité qui ravissent et impressionnent.",
      CAREER_P17: "Nous contacter",
      CAREER_P18: "Producteur/Planificateur",
      CAREER_P19:
        "Nos producteurs sont le ciment de nos équipes. Excellent communicateur et leader, vous savez comment faire en sorte que les choses se passent sans accroc entre nos clients et nos équipes, et vous avez ce qu'il faut pour maintenir le navire à flot. Vous avez une expérience sur tous les aspects du bon fonctionnement d'une société de jeux et vous ferez tout pour éliminer les obstacles à la création du meilleur logiciel possible pour nous et nos clients.",
      CAREER_P20: "Vous serez l'un ou tous les éléments suivants :",
      CAREER_P21:
        "Construire des relations avec les équipes de développement internes et les partenaires externes.",
      CAREER_P22:
        "Maîtrise des outils et des méthodologies de gestion de projet.",
      CAREER_P23:
        "Avoir un esprit de marketing, comprendre pour qui nous construisons, ce qu'ils veulent et comment s'engager avec eux.",
      CAREER_P24:
        "Un leader pragmatique capable de se mettre les mains à la tâche et de participer à n'importe quelle partie du processus de développement.",
      CAREER_P25: "Artiste/Designer",
      CAREER_P26:
        "Nos artistes apportent un design intuitif et une belle esthétique aux choses que nous faisons. Ils peuvent prendre la graine d'une idée dans la tête de quelqu'un d'autre, un bout d'écriture, un morceau de musique, une idée de produit, et ils peuvent lui donner forme. Aussi à l'aise dans un moteur de jeu créant des actifs de niveau production, qu'en dehors travaillant avec des concepts, du matériel de présentation, des wireframes et ainsi de suite, vous êtes capable de produire un travail qui impressionne et motive notre personnel et nos clients.",
      CAREER_P27:
        "Construire des argumentaires convaincants pour faire avancer les nouveaux projets et les nouvelles idées.",
      CAREER_P28:
        "Construire de beaux wireframes en gardant fermement à l'esprit les besoins de l'utilisateur final et les livrer de manière à faciliter le travail des développeurs.",
      CAREER_P29:
        "Concevoir des idées à partir du plus petit noyau d'une idée, et lui donner une forme, en communiquant avec les autres pour donner vie à leurs idées",
      CAREER_P30:
        "Construire les actifs du jeu, notamment les personnages, l'interface utilisateur, les environnements et tout ce qui est nécessaire à la création d'un jeu.",
      CAREER_P31:
        "Si l'un de ces postes vous ressemble, contactez-nous avec une présentation, un portfolio, un CV et une lettre de motivation.",
      CAREER_P32:
        "Nous accueillons les personnes ayant une attitude agile, une curiosité d'enfant et un désir inextinguible de croissance et d'apprentissage continus.",
      CAREER_P33: "Veuillez consulter le site",
      CAREER_P33_2:
        "pour avoir un aperçu de notre équipe actuelle et de nos projets passés.",
      CAREER_P34:
        "Nous pensons que chacun a sa propre façon de travailler, les horaires qui lui conviennent le mieux et nous croyons qu'il faut faire confiance à nos employés pour prendre des décisions judicieuses concernant leur propre gestion.",
      CAREER_P35:
        "Une fois intégré à nos flux et processus de travail, le travail à distance est une option et vous êtes libre de travailler selon l'horaire qui vous convient le mieux.",
      CAREER_P36:
        "Nous accueillons toutes les nationalités et identités et nous croyons en la création d'espaces pour encourager les discussions franches, réduire la peur de l'échec et fournir un environnement où une véritable collaboration peut s'épanouir. Nous respectons les différences de chacun, nous ne tolérons pas la discrimination et nous croyons en l'inclusion et l'accueil de tous. Nous accueillons et embrassons les défis que cela représente et nous nous concentrons sur la création d'une organisation de classe mondiale qui accueille les talents de tous horizons.",
      CAREER_DIVERSITY:
        "NOTRE POLITIQUE D'ÉGALITÉ, DE DIVERSITÉ ET D'INCLUSION",

      CAREER_P37:
        "Ce sont les postes spécifiques que nous avons ouverts actuellement sur des projets actifs. Veuillez postuler en indiquant le titre qui vous intéresse à",
      CAREER_P38: "cette adresse",
      CAREER_P39_HEADER: "Chef de Projet Expérimenté :",
      CAREER_P39_LIST_1:
        "Compétences en japonais natif, compétences intermédiaires à avancées en anglais",
      CAREER_P39_LIST_2: "Travailler sur de larges projets Flutter / Dart",
      CAREER_P39_LIST_3:
        "Soutenir les communications techniques sur les projets japonais, capable de travailler dans un environnement de travail complexe et multi-fournisseurs japonais",
      CAREER_P39_LIST_4:
        "Désir de travailler dans un environnement international",
      CAREER_P39_LIST_5:
        "Veuillez noter que ce poste implique une grande quantité de travail chez les clients et serait donc basé à Tokyo",
      CAREER_P40_HEADER: "Ingénieur Flutter (X2) :",
      CAREER_P40_LIST_1:
        "Travailler sur de larges projets Flutter / Dart en construisant des composants UI",
      CAREER_P40_LIST_2:
        "Compétences en anglais (vous travaillerez dans une équipe majoritairement anglophone)",
      CAREER_P40_LIST_3:
        "Une expérience en Design System / Component Design Methodologies est souhaitable",
      CAREER_P40_LIST_4:
        "Flexible et capable de s'adapter rapidement à de nouvelles tâches",
      CAREER_P40_LIST_5: "Télétravail possible. Candidats étrangers bienvenus.",
      CAREER_P41_HEADER: "Designer Sr. UI/UX :",
      CAREER_P41_LIST_1:
        "Expérience dans la conception d'applications mobiles grand public en utilisant un design system",
      CAREER_P41_LIST_2:
        "Grande maîtrise de Figma et, idéalement, familiarité avec des design tokens",
      CAREER_P41_LIST_3:
        "Grande attention aux détails, design patterns, typographie et à jour avec les dernières tendances en matière de design",
      CAREER_P42: "Postes Généraux",
      CAREER_P43:
        "Nous sommes toujours à la recherche de personnes curieuses, inquisitives et talentueuses pour rejoindre notre équipe. Si vous pensez correspondre à l'un des rôles ci-dessous, veuillez nous contacter",

      COMMERCIAL_DISCLOSURE: "Information commerciale",
      SCTA_COMMERCIAL_DISCLOSURE: "Commercial Disclosure",
      SCTA_DESCRIPTION: "",
      SCTA_LEGAL_NAME: "Legal Name",
      SCTA_LEGAL_NAME_A: "NekoLogic",
      SCTA_ADDRESS: "Address",
      SCTA_ADDRESS_A:
        "162-6-401 Jodoji Shinnyocho, Sakyo Ward, Kyoto, 606-8414",
      SCTA_PHONE_NUMBER: "Phone Number",
      SCTA_PHONE_NUMBER_A: "080-7643-3587",
      SCTA_EMAIL_ADDRESS: "Email Address",
      SCTA_EMAIL_ADDRESS_A: "liam@neko-logic.com",
      SCTA_HEAD_OF_OPERATIONS: "Head of operations",
      SCTA_HEAD_OF_OPERATIONS_A: "Liam Conroy",
      SCTA_ADDIONAL_FEES: "Additional Fees",
      SCTA_ADDIONAL_FEES_A:
        "None or as stipulated and agreed to by contract. Credit card transactions may incur an additional 4% fee as agreed upon in the contract. The customer is responsible for any internet connection charges, communication charges, server usage charges for product use, license charges for third-party products, eg. content purchases, software purchase, downloadable content and such.",
      SCTA_EXCHANGES_AND_RETURNS_POLICY: "Exchanges and returns policy",
      SCTA_EXCHANGES_AND_RETURNS_POLICY_A:
        "For any discrepencies or concerns after delivery and payment, please contact customer support: liam@neko-logic.com, this will vary from project to project.",
      SCTA_DELIVERY_TIMES: "Delivery Times",
      SCTA_DELIVERY_TIMES_A:
        "N/A, immediate, continual and or by contractual agreement.",
      SCTA_ACCEPTED_PAYMENT_METHODS: "Accepted Payment Methods",
      SCTA_ACCEPTED_PAYMENT_METHODS_A: "Direct Bank transfer, Credit Card.",
      SCTA_PAYMENT_PERIOD: "Payment Period",
      SCTA_PAYMENT_PERIOD_A:
        "Payments are expected as stipulated under the DUE DATE section in the sent invoice.",
      SCTA_PRICE: "Price",
      SCTA_PRICE_A: "Price is dependent on as per agreed contract.",
    },
    es: {
      HELLO: "Hola",
      HOME: "INICIO",
      SERVICES: "SERVICIOS",
      WORKS: "TRABAJOS",
      ABOUT: "A PROPOSITO",
      ABOUT_US: "SOBRE NOSOTROS",
      GET_IN_TOUCH: "Póngase en contacto con nosotros a través de:",
      FOLLOW_US: "SÍGANOS",
      SKILLS: "Competencias",
      NO_RESULT_FOUND: "Ningún resultado",
      NEKOLOGIC_FOOTER_DESCRIPTION:
        "es un estudio que se enfoca en la interacción entre lo digital y el mundo real. Nos enfocamos en AR, IoT, aprendizaje asistido por juegos y otros medios interactivos.",
      PRIVACY_POLICY: "Política de confidencialidad",
      FEATURED_WORKS: "Trabajos destacados",
      CLIENT_PROJECTS: "Proyectos para clientes",
      NEKO_LOGIC_PROJECTS: "Proyectos de Nekologic",
      IN_DEVELOPMENT: "En desarrollo",
      OTHER: "Otro",
      CAREER: "carreras",
      POSITIONS: "Puestos",
      POSITION_OPEN: "Puestos Vacantes",
      POSITION_GENERAL: "Puestos Generales",
      OUR_MEMBERS: "Nuestros miembros",
      WHAT_WE_DO: "Nuestros servicios",
      PLATFORMS: "PLATAFORMAS",
      PLATFORMS_TECHNOLOGY: "PLATAFORMAS | TECNOLOGÍAS",
      WHO_WE_WORK_WITH: "CON QUIÉN TRABAJAMOS",
      WHAT_WE_OFFER: "Nuestras ofertas",
      SERVICES_PARAGRAPH_1:
        "Somos un equipo altamente experimentado de profesionales creativos con sede en Japón. Utilizamos tecnologías de punta, combinadas con técnicas modernas de producción de software y nuestra pasión por el aprendizaje y el desarrollo, no solo desarrollamos nuestros propios productos, sino que también logramos los objetivos de nuestros clientes.",
      SERVICES_PARAGRAPH_2:
        "Nos enfocamos en la intersección entre el mundo real y el mundo digital, nuestras experiencias nos han puesto en el camino hacia tecnologías tan diversas como la realidad aumentada, realidad virtual, blockchain y mucho más.",
      SERVICES_PARAGRAPH_3:
        "Hemos completado con éxito más de 50 proyectos de desarrollo de software con una serie de tecnologías que incluyen VR, web, móvil y muchas más. Contratamos a los mejores y más creativos para ejecutar sus diseños y proyectos. Con una cartera de trabajo de clientes, así como nuestras propias herramientas, productos y tecnologías, permítanos ayudarlo a alcanzar sus deseos.",
      SERVICES_PARAGRAPH_4:
        "Trabajamos con una red muy unida de los mejores profesionales creativos que incluye desarrolladores de software, diseñadores, artistas, traductores, músicos, fotógrafos, cineastas y más. Podemos proporcionar todo, desde recursos para proyectos más grandes hasta soluciones completas para sus necesidades creativas. Aquí hay una muestra de los servicios que podemos brindar:",
      SOFTWARE_DEVELOPMENT_AND_CONSULTING:
        "Desarrollo y Consultoría de Software:",
      SOFTWARE_DEVELOPMENT_AND_CONSULTING_TEXT:
        "Desde el diseño hasta el mantenimiento y todos los pasos intermedios, podemos hacer prototipos, escalar, planificar, producir, probar y apoyarlo en cada paso del proceso de desarrollo.",
      RESEARCH_AND_DEVELOPMENT: "Investigación y desarrollo:",
      RESEARCH_AND_DEVELOPMENT_TEXT:
        "Aproveche nuestra mentalidad innovadora y curiosa para facilitar la exploración de futuras mejoras, servicios y productos.",
      DESIGN_WEB_MOBILE: "Diseño de interfaz de usuario:",
      DESIGN_WEB_MOBILE_TEXT:
        "Llame a nuestros diseñadores para que lo ayuden con cualquier escenario de diseño que pueda tener. ¿Tienes una idea y la necesitas para convertirla en un producto? Te tenemos cubierto. ¿Necesita mejorar la estética de un diseño existente? Mandanos un mensaje.",
      GAME_DESIGN: "Diseño de juego:",
      GAME_DESIGN_TEXT:
        "Tenemos una amplia experiencia en la creación de juegos y sus requisitos únicos. Permítanos ayudarlo a llevar sus ideas de juego desde el concepto hasta la ejecución.",
      ASSET_AND_CONTENTS_CREATION: "Creación de contenido:",
      ASSET_AND_CONTENTS_CREATION_TEXT:
        "Llame a nuestra extensa red de músicos, ingenieros de sonido, artistas, cineastas, fotógrafos y diseñadores para ayudarlo a satisfacer sus necesidades de contenido.\n\nArte y animación 2D/3D, música, sonidos, foto/video, contenido en 360°, etc.",
      TRANSLATION_AND_PROOFREADING: "Traducción y revisión:",
      TRANSLATION_AND_PROOFREADING_TEXT:
        "Contamos con una extensa red de traductores, revisores, redactores e intérpretes para satisfacer sus necesidades de traducción y comunicación.",
      CONSULTING: "Consultoria:",
      CONSULTING_TEXT:
        "Aproveche nuestra experiencia creativa, de desarrollo comercial para ayudar a sus proyectos y negocios.",
      INSTALLATIONS: "Instalaciones y experiencias AR/VR/MR:",
      INSTALLATIONS_TEXT:
        "Lleva tus eventos, aplicaciones y software al siguiente nivel con las últimas tecnologías de realidad virtual y aumentada que podemos diseñar para tus proyectos.",
      DONT_HESITATE:
        "¡No dudes en contactarnos para ayudarte con tus objetivos de negocio!",
      ABOUT_US_PARAGRAPH_1:
        "NekoLogic es una empresa de desarrollo de software con sede en Kioto, Japón.",
      ABOUT_US_PARAGRAPH_2:
        "Combinamos un alto nivel de habilidades de desarrollo, personal concienzudo, profunda experiencia técnica, un entorno internacional y procesos de desarrollo modernos y actualizados para crear un excelente software para nuestros clientes y para nosotros mismos.",
      GAMES: "Juegos",
      GAMES_TEXT:
        "Creemos en el poder de la mecánica del juego y en su capacidad para motivar a los jugadores. Creemos que podemos deleitar, educar y hacer que el aprendizaje sea tan divertido como los videojuegos.",
      LEARNING: "Aprendizaje",
      LEARNING_TEXT:
        "Creemos que las tecnologías avanzadas cambiarán la forma en que interactuamos con el mundo real. Desarrollamos productos divertidos e interactivos que conectan este mundo con lo digital y permiten el aprendizaje de habilidades de la vida real.",
      CRAFT: "Saber hacer",
      CRAFT_TEXT:
        "Creemos en la necesidad de superarnos y potenciar nuestro saber hacer y utilizar las mejores prácticas modernas de desarrollo y técnicas ágiles de producción. Tenemos experiencia con dispositivos móviles, VR, blockchain, AR, IoT, web y más.",
      NEKOTOWER: "¡Una vista de la Neko-Tower, donde ocurre toda la magia!",
      THIS_COULD_BE_YOU: "¡ESTO PODRÍAS SER TÚ! ",
      THIS_COULD_BE_YOU_TEXT:
        "Siempre estamos buscando personas talentosas e independientes. Trabajamos con una serie de tecnologías geniales y con una gran cantidad de clientes interesantes. ¡Contáctenos si está interesado!",
      ABOUT_PAGE_PARAGRAPH_1:
        "Nekologic es una empresa de desarrollo de software avanzado con sede en Kioto, Japón.",
      ABOUT_PAGE_PARAGRAPH_2:
        "Combinamos un alto nivel de experiencia en desarrollo, personal concienzudo, profunda experiencia técnica,",
      ABOUT_PAGE_PARAGRAPH_3:
        "Un entorno internacional y procesos de desarrollo modernos y actualizados para crear un gran software para nuestros clientes y para nosotros mismos.",
      WHAT_WE_BELIEVE_IN: "En lo que creemos",
      SUSTAINABLE_DEVELOPMENT: "DESARROLLO SOSTENIBLE Y POSITIVIDAD",
      SUSTAINABLE_DEVELOPMENT_TEXT:
        "Creemos en los maratones, no en los sprints. El mundo está cambiando paso a paso.\nEstablecemos metas ambiciosas, pero alcanzables y sostenibles para trabajar hacia un futuro mejor.",
      SELF_MANAGEMENT: "AUTOGESTIÓN",
      SELF_MANAGEMENT_TEXT:
        "Nos preocupamos por lo que hacemos y lo que poseemos.\nCreemos que las personas conscientes y motivadas logran grandes cosas juntas.",
      CONSIDERATION_FOR_ALL: "CONSIDERACIÓN PARA TODOS",
      CONSIDERATION_FOR_ALL_TEXT:
        "Para el cliente, para el equipo y para la sociedad.\nNos esforzamos por comprender incluso a las partes interesadas no obvias y actuar en el mejor interés de todos.",
      CUTTING_EDGE_CRAFT: "Logros",
      CUTTING_EDGE_CRAFT_TEXT:
        "Todos los miembros persiguen con pasión el desarrollo profesional continuo ofreciendo habilidades variadas y de vanguardia y listos para enfrentar cualquier desafío.",
      WHOLENESS: "INTEGRIDAD",
      WHOLENESS_TEXT:
        "Creemos en fomentar un entorno completo y diverso con una miríada de habilidades y personalidades únicas.",
      EMERGENT_PURPOSE: "OBJETIVO EMERGENTE",
      EMERGENT_PURPOSE_TEXT:
        "Creemos que es mucho más valioso 'escuchar y adaptarse' que 'predecir y controlar'.",
      ADDRESS:
        "162-6-401 Jodoji Shinnyocho,\n Sakyo Ward, Kioto,\n 606-8414, Japón",
      ADDRESS_UK:
        "37-45, Windsor Street,\n Liverpool, Merseyside,\n Reino Unido, L8 1XE",
      PRIVACY_POLICY_TITLE: "POLÍTICA DE PRIVACIDAD ES",
      PRIVACY_POLICY_PARAGRAPH_1:
        "Effective date: October 12, 2018\n\n合同会社NekoLogic (“us”, “we”, or “our”) operates the http://neko-logic.com website (the “Service”)\n\nThis page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.\n\nWe use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from http://neko-logic.com",
      INFORMATION_COLLECTION: "INFORMATION COLLECTION AND USE",
      INFORMATION_COLLECTION_PARAGRAPH_1:
        "We collect several different types of information for various purposes to provide and improve our Service to you",
      DATA_COLLECTED: "TYPES OF DATA COLLECTED",
      PERSONAL_DATA: "PERSONAL DATA",
      PERSONAL_DATA_PARAGRAPH_1:
        "While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:",
      PERSONAL_DATA_ITEM_1: "Email address",
      PERSONAL_DATA_ITEM_2: "Cookies and Usage Data",
      USAGE_DATA: "USAGE DATA",
      USAGE_DATA_PARAGRAPH_1:
        "We may also collect information how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data",
      TRACKING_DATA: "TRACKING & COOKIES DATA",
      TRACKING_DATA_PARAGRAPH_1:
        "We use cookies and similar tracking technologies to track the activity on our Service and hold certain information\n\nCookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service\n\nYou can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service\n\nExamples of Cookies we use:",
      TRACKING_DATA_ITEM_1_STRONG: "Session Cookies. ",
      TRACKING_DATA_ITEM_1: "We use Session Cookies to operate our Service.",
      TRACKING_DATA_ITEM_2_STRONG: "Preference Cookies. ",
      TRACKING_DATA_ITEM_2:
        "We use Preference Cookies to remember your preferences and various settings.",
      TRACKING_DATA_ITEM_3_STRONG: "Security Cookies. ",
      TRACKING_DATA_ITEM_3: "We use Security Cookies for security purposes.",
      USE_OF_DATA: "USE OF DATA",
      USE_OF_DATA_PARAGRAPH_1:
        "合同会社NekoLogic uses the collected data for various purposes:",
      USE_OF_DATA_ITEM_1: "To provide and maintain the Service",
      USE_OF_DATA_ITEM_2: "To notify you about changes to our Service",
      USE_OF_DATA_ITEM_3:
        "To allow you to participate in interactive features of our Service when you choose to do so",
      USE_OF_DATA_ITEM_4: "To provide customer care and support",
      USE_OF_DATA_ITEM_5:
        "To provide analysis or valuable information so that we can improve the Service",
      USE_OF_DATA_ITEM_6: "To monitor the usage of the Service",
      USE_OF_DATA_ITEM_7: "To detect, prevent and address technical issues",
      TRANSFER_OF_DATA: "TRANSFER OF DATA",
      TRANSFER_OF_DATA_PARAGRAPH_1:
        "Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction\n\nIf you are located outside Japan and choose to provide information to us, please note that we transfer the data, including Personal Data, to Japan and process it there\n\nYour consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer\n\n合同会社NekoLogic will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information",
      DISCLOSURE_OF_DATA: "DISCLOSURE OF DATA",
      LEGAL_REQUIREMENTS: "LEGAL REQUIREMENTS",
      LEGAL_REQUIREMENTS_PARGRAPH_1:
        "合同会社NekoLogic may disclose your Personal Data in the good faith belief that such action is necessary to:",
      LEGAL_REQUIREMENTS_ITEM_1: "To comply with a legal obligation",
      LEGAL_REQUIREMENTS_ITEM_2:
        "To protect and defend the rights or property of 合同会社NekoLogic",
      LEGAL_REQUIREMENTS_ITEM_3:
        "To prevent or investigate possible wrongdoing in connection with the Service",
      LEGAL_REQUIREMENTS_ITEM_4:
        "To protect the personal safety of users of the Service or the public",
      LEGAL_REQUIREMENTS_ITEM_5: "To protect against legal liability",
      SECURITY_OF_DATA: "SECURITY OF DATA",
      SECURITY_OF_DATA_PARAGRAPH_1:
        "The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security",
      SERVICE_PROVIDERS: "SERVICE PROVIDERS",
      SERVICE_PROVIDERS_PARAGRAPH_1:
        "We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used\n\nThese third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose",
      ANALYTICS: "ANALYTICS",
      ANALYTICS_PARAGRAPH_1:
        "We may use third-party Service Providers to monitor and analyze the use of our Service",
      ANALYTICS_PARAGRAPH_2:
        "For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: ",
      ANALYTICS_ITEM_1_STRONG: "Google Analytics ",
      ANALYTICS_ITEM_1:
        "Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising networkYou can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity",
      LINKS_TO_OTHER_SITES: "LINKS TO OTHER SITES",
      LINKS_TO_OTHER_SITES_PARAGRAPH_1:
        "Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit\n\nWe have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services",
      CHILDRENS_PRIVACY: "CHILDREN’S PRIVACY",
      CHILDRENS_PRIVACY_PARAGRAPH_1:
        "Our Service does not address anyone under the age of 18 (“Children”)\n\nWe do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers",
      CHANGES_TO_PRIVACY_POLICY: "CHANGES TO THIS PRIVACY POLICY",
      CHANGES_TO_PRIVACY_POLICY_PARAGRAPH_1:
        "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page\n\nWe will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy\n\nYou are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page",
      CONTACT_US: "CONTACTO",
      CONTACT_US_PARAGRAPH_1:
        "Si tiene alguna pregunta sobre esta Política de privacidad, contáctenos:",
      CONTACT_US_ITEM_1: "Por correo electrónico: liam@neko-logic.com",

      CAREER_P1:
        "Nekologic está siempre a la búsqueda de gatos con talento que quieran unirse a nuestra tripulación",
      CAREER_P2:
        "Se ofrece al candidato adecuado apoyo para la visa (¡depende del Corona!), ayuda para el alojamiento, salarios competitivos y la posibilidad de vivir y trabajar en la maravillosa ciudad de Kyoto. Es posible un comienzo a distancia para un candidato motivado y con conocimientos de trabajo a distancia con las habilidades y la actitud adecuadas",
      CAREER_P3:
        "Seguimos siendo una empresa muy pequeña; nuestros equipos están formados por miembros curiosos, inquisitivos y motivados que llevan muchos sombreros en un día determinado. Las descripciones de los puestos de trabajo que figuran a continuación son descripciones aproximadas de los puestos generales que tenemos, pero lo más importante es la actitud positiva, el deseo de superación y la tenacidad para llevar los proyectos hasta el final. Nos encantaría saber de ti",
      CAREER_P4: "Ingeniero",
      CAREER_P5:
        "Nuestros ingenieros son el núcleo de lo que hacemos. Diseñan, desarrollan, mantienen y modifican el software que construimos para nosotros y nuestros clientes. Son personas que resuelven problemas de forma innovadora, con excelentes habilidades de comunicación y una mentalidad intelectualmente curiosa, y construyen software que nos deleita a nosotros y a nuestros clientes",
      CAREER_P6: "La mayor parte de nuestro software se construye en:",
      CAREER_P7: "Frontend",
      CAREER_P8: "Unity",
      CAREER_P9: "Vue.js",
      CAREER_P10: "React",
      CAREER_P11: "Backend",
      CAREER_P12: "Python",
      CAREER_P13: "C#/DotNet",
      CAREER_P14: "PHP",
      CAREER_P15:
        "Sin embargo, también hemos construido productos en C++, WebGL, Ruby On Rails y muchos más",
      CAREER_P16:
        "Nuestros proyectos van desde el escritorio a la web, aplicaciones de RV a juegos de consola.Creemos firmemente en una mentalidad agnóstica de la tecnología y la plataforma.Lo que es clave es construir productos increíbles y de alta calidad que deleiten e impresionen.",
      CAREER_P17: "Contactanos",
      CAREER_P18: "Productor/Planificador",
      CAREER_P19:
        "Nuestros productores son el pegamento que ayuda a unir a nuestros equipos. Un excelente comunicador y líder, que sabe cómo mantener las cosas suaves entre nuestros clientes y nuestros equipos, y tiene lo que se necesita para mantener el barco a flote.Tiene experiencia en todos los aspectos de mantener una empresa de juegos funcionando sin problemas y hará cualquier cosa para eliminar cualquier desafío en el camino de hacer el mejor software que podamos para nosotros y nuestros clientes ",
      CAREER_P20: "Serás uno o todos los siguientes:",
      CAREER_P21:
        "Un constructor de relaciones tanto con los equipos de desarrollo internos como con los socios externos",
      CAREER_P22:
        "Dominio de las herramientas y metodologías de gestión de proyectos",
      CAREER_P23:
        "Tener una mentalidad de marketing, entender para quién construimos, qué quieren y cómo comprometerse con ellos",
      CAREER_P24:
        "Un líder práctico capaz de ensuciarse las manos y participar en cualquier parte del proceso de desarrollo",
      CAREER_P25: "Artista/diseñador",
      CAREER_P26:
        "Nuestros artistas aportan un diseño intuitivo y una bella estética a las cosas que hacemos.Pueden tomar una semilla de una idea en la cabeza de otra persona, un trozo de escritura, un poco de música, una idea de producto, y pueden sacarla al mundo y darle forma.Igualmente cómodo dentro de un motor de juego creando activos a nivel de producción, o fuera trabajando con conceptos, material de lanzamiento, wireframes y demás, eres capaz de producir un trabajo que asombra y motiva a nuestro personal y a nuestros clientes",
      CAREER_P27:
        "Crear material de presentación convincente para impulsar nuevos proyectos e ideas",
      CAREER_P28:
        "Construir hermosos wireframes con las necesidades del usuario final firmemente en mente y entregarlos de una manera que hace el trabajo de los desarrolladores fácil",
      CAREER_P29:
        "Concebir ideas a partir del núcleo más pequeño de una idea, y darle forma, comunicándose con otros para dar vida a sus ideas",
      CAREER_P30:
        "Construir los activos del juego, incluidos los personajes, la interfaz de usuario, los entornos y cualquier otra cosa necesaria para construir un juego",
      CAREER_P31:
        "Si algo de lo anterior se parece a ti, ponte en contacto con nosotros con una presentación, un portafolio, un CV y una carta de presentación",
      CAREER_P32:
        "Damos la bienvenida a aquellos con actitudes ágiles, curiosidad infantil y un deseo insaciable de crecimiento y aprendizaje continuos",
      CAREER_P33: "Por favor, echa un vistazo a",
      CAREER_P33_2:
        "para tener una visión general de nuestro equipo actual y de los proyectos anteriores",
      CAREER_P34:
        "Creemos que cada uno tiene su propia forma de trabajar, los horarios que mejor le funcionan y creemos en la confianza de nuestros empleados para que tomen decisiones sensatas sobre su propia autogestión",
      CAREER_P35:
        "Una vez que se ha incorporado a nuestros flujos de trabajo y procesos, el trabajo a distancia es una opción y usted es libre de trabajar en el horario que más le convenga",
      CAREER_P36:
        "Nekologic es una empresa de igualdad de oportunidades con un equipo diverso e inclusivo formado por varios países y multitud de orígenes. Damos la bienvenida a todas las nacionalidades e identidades y creemos en la creación de espacios que fomenten el debate sincero, reduzcan el miedo al fracaso y proporcionen un entorno en el que pueda prosperar la verdadera colaboración. Respetamos las diferencias de los demás, no toleramos la discriminación y creemos en la inclusión y la acogida de todos. Damos la bienvenida y abrazamos los retos que esto representa y estamos centrados en hacer una organización de clase mundial que acoja el talento de todos los orígenes.",

      CAREER_P37:
        "Estos son los roles específicos que tenemos abiertos ahora en proyectos activos. Por favor, aplique, indicando el título que le interesa a",
      CAREER_P38: "esta dirección",
      CAREER_P39_HEADER: "Gerente de Proyecto Experimentado:",
      CAREER_P39_LIST_1:
        "Habilidades nativas en japonés, habilidades intermedias a altas en inglés",
      CAREER_P39_LIST_2: "Trabajar sobre grandes proyectos en Flutter / Dart",
      CAREER_P39_LIST_3:
        "Apoyar las comunicaciones técnicas en los proyectos japoneses, capaz de trabajar en un entorno de trabajo japonés complejo y multi-proveedores",
      CAREER_P39_LIST_4: "Deseo de trabajar en un entorno internacional",
      CAREER_P39_LIST_5:
        "Tenga en cuenta que este puesto implica una gran cantidad de trabajo con clientes y, por lo tanto, estaría basado en Tokio",
      CAREER_P40_HEADER: "Ingeniero Flutter (X2):",
      CAREER_P40_LIST_1:
        "Trabajar en grandes proyectos en Flutter / Dart construyendo componentes de UI",
      CAREER_P40_LIST_2:
        "Habilidades de habla inglesa (trabajarás en un equipo predominantemente de habla inglesa)",
      CAREER_P40_LIST_3:
        "Deseable experiencia en Sistemas de Diseño / Metodologías de Diseño de Componentes",
      CAREER_P40_LIST_4:
        "Flexible y capaz de adaptarse rápidamente a nuevas tareas",
      CAREER_P40_LIST_5:
        "Teletrabajo permitido. Se aceptan candidatos extranjeros.",
      CAREER_P41_HEADER: "Diseñador Sr. UI/UX:",
      CAREER_P41_LIST_1:
        "Experiencia en el diseño de aplicaciones móviles para el consumidor utilizando sistemas de diseño",
      CAREER_P41_LIST_2:
        "Alta competencia en Figma y, idealmente, familiaridad con tokens de diseño",
      CAREER_P41_LIST_3:
        "Alta atención a los detalles, patrones de diseño, tipografía y al día con las últimas tendencias de diseño",
      CAREER_P42: "Puestos Generales",
      CAREER_P43:
        "Siempre estamos buscando personas curiosas, inquisitivas y talentosas para unirse a nuestro equipo. Si siente que cumple con uno de los roles a continuación, por favor contáctenos",

      CAREER_DIVERSITY: "Nuestra política de igualdad, diversidad e inclusión",
      COMMERCIAL_DISCLOSURE: "Información comercial",
      SCTA_COMMERCIAL_DISCLOSURE: "Commercial Disclosure",
      SCTA_DESCRIPTION: "",
      SCTA_LEGAL_NAME: "Legal Name",
      SCTA_LEGAL_NAME_A: "NekoLogic",
      SCTA_ADDRESS: "Address",
      SCTA_ADDRESS_A:
        "162-6-401 Jodoji Shinnyocho, Sakyo Ward, Kyoto, 606-8414",
      SCTA_PHONE_NUMBER: "Phone Number",
      SCTA_PHONE_NUMBER_A: "080-7643-3587",
      SCTA_EMAIL_ADDRESS: "Email Address",
      SCTA_EMAIL_ADDRESS_A: "liam@neko-logic.com",
      SCTA_HEAD_OF_OPERATIONS: "Head of operations",
      SCTA_HEAD_OF_OPERATIONS_A: "Liam Conroy",
      SCTA_ADDIONAL_FEES: "Additional Fees",
      SCTA_ADDIONAL_FEES_A:
        "None or as stipulated and agreed to by contract. Credit card transactions may incur an additional 4% fee as agreed upon in the contract. The customer is responsible for any internet connection charges, communication charges, server usage charges for product use, license charges for third-party products, eg. content purchases, software purchase, downloadable content and such.",
      SCTA_EXCHANGES_AND_RETURNS_POLICY: "Exchanges and returns policy",
      SCTA_EXCHANGES_AND_RETURNS_POLICY_A:
        "For any discrepencies or concerns after delivery and payment, please contact customer support: liam@neko-logic.com, this will vary from project to project.",
      SCTA_DELIVERY_TIMES: "Delivery Times",
      SCTA_DELIVERY_TIMES_A:
        "N/A, immediate, continual and or by contractual agreement.",
      SCTA_ACCEPTED_PAYMENT_METHODS: "Accepted Payment Methods",
      SCTA_ACCEPTED_PAYMENT_METHODS_A: "Direct Bank transfer, Credit Card.",
      SCTA_PAYMENT_PERIOD: "Payment Period",
      SCTA_PAYMENT_PERIOD_A:
        "Payments are expected as stipulated under the DUE DATE section in the sent invoice.",
      SCTA_PRICE: "Price",
      SCTA_PRICE_A: "Price is dependent on as per agreed contract.",
    },
  },
});
