<template>
  <b-navbar
    toggleable="lg"
    id="nav"
    :sticky="WindowWidth"
    type="dark"
    class="navbar-custom"
  >
    <div class="mx-5 hidden_small" />
    <b-navbar-brand href="#" class="headerlogo" @click="$router.push('/')">
      <img src="../assets/nekologiclogo.svg" alt="" class="logo" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item class="px-3" @click="scrollToTop" to="/">{{
          $t("HOME")
        }}</b-nav-item>
        <b-nav-item class="px-3" @click="scrollToTop" to="/works">{{
          $t("WORKS")
        }}</b-nav-item>
        <b-nav-item class="px-3" @click="scrollToTop" to="/services">{{
          $t("SERVICES")
        }}</b-nav-item>
        <b-nav-item class="px-3" @click="scrollToTop" to="/about"
          >{{ $t("ABOUT") }}
        </b-nav-item>
        <b-nav-item class="px-3" @click="scrollToTop" to="/career"
          >{{ $t("CAREER") }}
        </b-nav-item>

        <b-img
          :src="flagForLang(lang)"
          fluid
          alt="Fluid image"
          :class="'flag hidden_small pl-3'"
        />

        <b-nav-item-dropdown class="px-3" :text="lang" right>
          <b-dropdown-item @click="ChangeLang('ENGLISH')">
            <b-img
              :src="require('../assets/countries/uk.png')"
              fluid
              alt="Fluid image"
              class="flag_dropdown flag_dropdown_uk"
            />
            <span class="dropdown">ENGLISH</span>
          </b-dropdown-item>
          <b-dropdown-item @click="ChangeLang('日本語')">
            <b-img
              :src="require('../assets/countries/jp.png')"
              fluid
              class="flag_dropdown flag_dropdown_jp"
            />
            <span class="dropdown">日本語</span>
          </b-dropdown-item>
          <b-dropdown-item @click="ChangeLang('FRANÇAIS')">
            <b-img
              :src="require('../assets/countries/fr.png')"
              fluid
              alt="Fluid image"
              class="flag_dropdown flag_dropdown_fr"
            />
            <span class="dropdown">FRANÇAIS</span>
          </b-dropdown-item>

          <b-dropdown-item @click="ChangeLang('ESPAÑOL')">
            <b-img
              :src="require('../assets/countries/es.png')"
              fluid
              alt="Fluid image"
              class="flag_dropdown flag_dropdown_es"
            />
            <span class="dropdown">ESPAÑOL</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <div class="mx-2 hidden_small" />
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      lang: "ENGLISH",
      screenWidth: window.innerWidth,
    };
  },
  created() {
    if (this.$i18n.locale === "en") {
      this.ChangeLang("ENGLISH");
    } else if (this.$i18n.locale === "jp") {
      this.ChangeLang("日本語");
    } else if (this.$i18n.locale === "fr") {
      this.ChangeLang("FRANÇAIS");
    } else if (this.$i18n.locale === "es") {
      this.ChangeLang("ESPAÑOL");
    }
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.screenWidth = window.innerWidth)
    );
  },
  computed: {
    WindowWidth() {
      if (this.screenWidth < 600) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    ChangeLang(newLang) {
      if (newLang == "日本語") this.$i18n.locale = "jp";
      if (newLang == "ENGLISH") this.$i18n.locale = "en";
      if (newLang == "FRANÇAIS") this.$i18n.locale = "fr";
      if (newLang == "ESPAÑOL") this.$i18n.locale = "es";
      this.lang = newLang;
    },
    flagForLang(lang) {
      var ret = null;
      switch (lang) {
        default:
          ret = require("../assets/countries/jp.png");
          break;
        case "ENGLISH":
          ret = require("../assets/countries/uk.png");
          break;
        case "FRANÇAIS":
          ret = require("../assets/countries/fr.png");
          break;
        case "ESPAÑOL":
          ret = require("../assets/countries/es.png");
          break;
      }
      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
/* 
  b-nav-item for individual menu items
  maybe find your widths on ml-auto if you want a slidey underscore thingee
*/
.ml-5,
.mx-5 {
  margin-left: 0px !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
/*
  I have no idea where ml and mx are coming from but they messing with the header alignment within hidden elements.
  This is a temporary fix just for the header. Please fix properly if you can.
*/
@keyframes logobounce {
  20% {
    transform: scale(98%);
  }
  40% {
    transform: scale(102%);
  }
  60% {
    transform: scale(99%);
  }
  80% {
    transform: scale(101%);
  }
}
@keyframes clicknav {
  0% {
    box-shadow: none;
    transform: translateY(2px);
  }
}
#nav {
  border-bottom: 5px solid #f4b346;
  padding: 15px;
  display: flex !important;
}
#nav a {
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  transition: 0.2s;
  padding-left: 20px;
  padding-right: 20px;
}
#nav a:hover {
  background-color: #c35a7d;
  color: #ffb300;
  /* box-shadow: 0px 0px 0px 6px #f2a75a; */
  /* text-shadow: #813c72 3px 3px 3px; */
  /* border-bottom: 6px solid #D67511; */
}
.pr-3,
.px-3 {
  padding-right: 0.3rem !important;
  padding-left: 0.3rem !important;
}
#nav a:active {
  animation: clicknav 0.4s;
}

#nav a.router-link-exact-active {
  background-color: #87314d;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  color: #ffbe28;
}
#nav a.router-link-exact-active:hover {
  background-color: #662c3f;
  /*  box-shadow: 0px 0px 0px 6px #d67511; */
  color: #ca9d34;
}
#nav .logo {
  transition: 0.2s;
}
#nav .headerlogo {
  padding: 0;
}

#nav .headerlogo:active {
  animation: logobounce 0.2s;
  opacity: 1;
}
#nav .headerlogo:hover {
  box-shadow: none;
  border-radius: 0;
  background-color: #ffbe28;
}
#nav .logo:hover {
  opacity: 0.8;
}
.flag {
  max-height: 30px;
  max-height: 30px;
  margin-top: auto;
  margin-bottom: auto;
}
.flag_dropdown {
  max-height: 24px;
  max-height: 24px;
  margin-right: 8px;
  border: 2px solid rgba(200, 200, 200, 0.4);
  border-radius: 100%;
}
.navbar-custom {
  background-color: #3c1c35;
  font-family: "Poppins", sans-serif;
}
.dropdown {
  color: #3c1c35;
}
.logo {
  display: block;
  height: 80px;
}
/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (max-width: 1140px) {
  .hidden_small {
    display: none;
  }
  .navbar-toggler {
    border-width: 0px;
    background-color: #532749;
  }
}
/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (max-width: 991px) {
  .hidden_small {
    display: none;
  }
  #nav a:hover {
    background-color: transparent;
    border: 0px transparent;
    box-shadow: none;
    text-shadow: none;
  }
  #nav a.router-link-exact-active {
    background-color: transparent;
    border-bottom: 0px transparent;
    box-shadow: none;
    color: #d67511;
    animation: none;
  }
  #nav a.router-link-exact-active:hover {
    background-color: transparent;
    border-bottom: 0px solid transparent;
    box-shadow: none;
    color: #d67511;
  }
  .navbar-collapse {
    padding-top: 10px;
  }
}
/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (max-width: 768px) {
  .logo {
    height: 50px;
  }
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 400px) {
  .logo {
    height: 40px;
  }
}
</style>
