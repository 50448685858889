import axios from "axios";

const useDevServer = false;
const overrideStubJSONs = false;

const baseDomain = useDevServer
  ? "http://localhost:8000/"
  : "https://nekohr.nekoapi.xyz/nekoapi/";

const baseURL = `${baseDomain}`;

const regularAxios = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

function getStubFunc(url, config) {
  console.log("getStub " + url + JSON.stringify(config));
  return new Promise((resolve, reject) => {
    const filenameFromUrl = url;
    const cleanedFilename =
      filenameFromUrl[0] == "/" ? filenameFromUrl.substr(1) : filenameFromUrl;

    fetch(cleanedFilename + ".json")
      .then((res) => {
        res
          .json()
          .then((val) => {
            resolve(val);
          })
          .catch((e) => reject(e));
      })
      .catch((e) => reject(e));
  });
}

const modifiedAxios = {
  ...regularAxios,
  get(url, config) {
    if (overrideStubJSONs) {
      return getStubFunc(url, config);
    } else {
      return regularAxios.get(url, config);
    }
  },
  getstub(url, config) {
    return getStubFunc(url, config);
  },
};

export default modifiedAxios;
