<template>
  <!--TODO:[refactor ProjectModal.vue]-->
  <div id="project-model">
    <div class="header-div">
      <div class="modal-header-left">
        <div class="circle-border-modal">
          <div
            class="img-circle-modal"
            :style="{'background-image': 'url(' + `${getImage}` + ')'}"
          ></div>
        </div>
        <div class="mt-2 text-header-left" style="width: 100%">
          <h4>
            <strong style="color: #532545"> {{ getProjectName }}</strong>
          </h4>
          <span
            v-for="(platform, index) in getProjectPlatform"
            :key="index"
            style="display: inline"
          >
            <b-badge pill class="mr-2" style="background-color: #ffbe28">{{
              platform.platform_name
            }}</b-badge>
          </span>
          <div class="mt-2">
            <span v-for="(icon, index) in getProjectPlatform" :key="index">
              <img
                class="mr-3 neko_icon"
                v-if="getIcon(icon.platform_id) !== ''"
                :src="getIcon(icon.platform_id)"
              />
            </span>
          </div>
          <div class="mt-2" v-if="getWebsiteUrl === ''"></div>
          <div v-else class="mt-2">
            <a :href="getWebsiteUrl" target="_blank">More Info</a>
          </div>
        </div>
      </div>
      <div class="modal-header-right">
        <b-carousel
          ref="myCarousel"
          id="carousel-1"
          v-model="slide"
          :interval="5000"
          fade
          background="white"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide v-for="img in getImages" :key="img.image_id">
            <template #img>
              <div style="display: flex">
                <b-icon
                  type="button"
                  @click="prev"
                  icon="chevron-left"
                  font-scale="2"
                  class="m-auto slider_control"
                ></b-icon>
                <img
                  class="img_carousel img"
                  :src="img.image_name"
                  alt="image slot"
                />
                <b-icon
                  @click="next"
                  type="button"
                  icon="chevron-right"
                  font-scale="2"
                  class="m-auto slider_control"
                ></b-icon>
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
    <div class="labels_list">
      <div class="labels_info" v-if="getProjectClient !== ''">
        <div>
          <strong class="modal-description-title">CLIENT:</strong>
        </div>
        <div>
          <p class="mb-0">{{ getProjectClient }}</p>
        </div>
      </div>

      <div class="labels_info" v-if="getProjectRole.length !== 0">
        <div>
          <strong class="modal-description-title">OUR ROLE:</strong>
        </div>
        <div>
          <span
            class="mb-2"
            v-for="(role, index) in getProjectRole"
            :key="index"
          >
            <b-badge pill class="mr-2" style="background-color: #85d4dd">{{
              role.role_name
            }}</b-badge>
          </span>
        </div>
      </div>
      <div class="labels_info" v-if="getProjectMember.length !== 0">
        <div>
          <strong class="modal-description-title">TEAM:</strong>
        </div>
        <div>
          <span
            class="mb-2"
            v-for="(member, index) in getProjectMember"
            :key="index"
          >
            <b-badge pill class="mr-2" style="background-color: #8bc53f">{{
              member.member_name
            }}</b-badge>
          </span>
        </div>
      </div>
      <div class="labels_info" v-if="getProjectSkill.length !== 0">
        <div>
          <strong class="modal-description-title">SKILLS:</strong>
        </div>
        <div>
          <span
            class="mb-2"
            v-for="(skill, index) in getProjectSkill"
            :key="index"
          >
            <b-badge pill class="mr-2" style="background-color: #b6374d">{{
              skill.skill_name
            }}</b-badge>
          </span>
        </div>
      </div>
      <div class="labels_info" v-if="getProjectBudget !== ''">
        <strong class="modal-description-title">BUDGET:</strong>
        <p class="modal-description-text">{{ getProjectBudget }}</p>
      </div>
    </div>
    <p v-html="getProjectDescription"></p>
  </div>
</template>

<script>
import proxy from "../services/proxy";

export default {
  props: ["id"],
  data() {
    return {
      slide: 0,
      sliding: null,
      innerData: {},
      icons: {
        Android: 1,
        iOS: 2,
        AR: 3,
        MR: 4,
        VR: 5,
        WEB: 6,
        Audio: 7,
        Locational: 8,
        Switch: 9,
        Game: 10,
        Installation: 11,
        Hardware: 12,
        Consulation: 13,
        Backend: 14,
      },
    };
  },
  computed: {
    getImage() {
      let img = this.innerData.image_url;
      if (
        this.innerData.image_url === undefined ||
        this.innerData.image_url === ""
      ) {
        img = require("../assets/default_img.png");
      }
      return img;
    },
    getImages() {
      const temp = [
        {
          image_name: this.getImage,
        },
      ];
      if (
        this.innerData?.images === undefined ||
        this.innerData.images.length === 0
      )
        return temp;
      return this.innerData.images;
    },
    getProjectName() {
      return this.innerData.project_name === undefined
        ? ""
        : this.innerData.project_name;
    },

    getProjectClient() {
      return this.innerData.client === undefined ||
        this.innerData.client.length === 0
        ? ""
        : this.innerData.client[0].client_name;
    },

    getProjectDescription() {
      const lang = this.$i18n.locale.toUpperCase();
      if (this.innerData.descriptions === undefined) return "";
      var value = "";
      this.innerData.descriptions.forEach((item) => {
        if (
          item.language_code === lang &&
          item.project_detail_description !== null
        ) {
          value = item.project_detail_description;
        }
      });
      if (value === "" || value === null) {
        return this.innerData.descriptions[0].project_detail_description;
      }
      return value;
    },
    getProjectMember() {
      return this.innerData.members === undefined ? "" : this.innerData.members;
    },
    getProjectRole() {
      return this.innerData.roles === undefined ? "" : this.innerData.roles;
    },
    getProjectSkill() {
      return this.innerData.skills === undefined ? "" : this.innerData.skills;
    },
    getProjectPlatform() {
      return this.innerData.platforms === undefined
        ? ""
        : this.innerData.platforms;
    },
    getProjectBudget() {
      return this.innerData.project_budget === undefined
        ? ""
        : this.innerData.project_budget;
    },
    getWebsiteUrl() {
      return this.innerData?.project_website === undefined ||
        this.innerData?.project_website === null ||
        this.innerData?.project_website === ""
        ? ""
        : this.innerData?.project_website;
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    getIcon(icon) {
      switch (icon) {
        case this.icons.Android:
          icon = require("../assets/icons-projects/Android.svg");
          break;
        case this.icons.iOS:
          icon = require("../assets/icons-projects/iOS.svg");
          break;
        case this.icons.AR:
          icon = require("../assets/icons-projects/AR.svg");
          break;
        case this.icons.MR:
          icon = require("../assets/icons-projects/MR.svg");
          break;
        case this.icons.VR:
          icon = require("../assets/icons-projects/VR.svg");
          break;
        case this.icons.WEB:
          icon = require("../assets/icons-projects/Web.svg");
          break;
        case this.icons.Audio:
          icon = require("../assets/icons-projects/Audio.svg");
          break;
        case this.icons.Locational:
          icon = require("../assets/icons-projects/Locational.svg");
          break;
        case this.icons.Switch:
          icon = require("../assets/icons-projects/Switch.svg");
          break;
        case this.icons.Game:
          icon = require("../assets/icons-projects/Game.svg");
          break;
        case this.icons.Installation:
          icon = require("../assets/icons-projects/Installation.svg");
          break;
        case this.icons.Hardware:
          icon = require("../assets/icons-projects/Hardware.svg");
          break;
        case this.icons.Consulation:
          icon = require("../assets/icons-projects/Consultation.svg");
          break;
        case this.icons.Backend:
          icon = require("../assets/icons-projects/Backend.svg");
          break;
        default:
          icon = "";
      }
      return icon;
    },
    async fetchData() {
      try {
        const response = await proxy.get(
          "/api/projects/inner_card/?project_id=" + this.id
        );
        if (response.data.status === 200) {
          this.innerData = response.data.result;
        }
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    },
  },
};
</script>

<style scoped>
#project-model {
  padding: 10px;
  margin: 5px;
}
.featured_image {
  position: relative;
  width: 50%;
  object-fit: contain;
}
.neko_icon {
  height: 35px;
  width: 35px;
}
.img-circle-modal {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.bigger-image-modal-div {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.modal-platforms {
  font-size: 10px;
}
.header-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 12px;
}
.modal-header-left {
  min-width: 40%;
  border-right: dotted 4px #d87b1c;
}
.text-header-left {
  text-align: center;
}
.modal-header-right {
  height: 400px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.modal-description-title {
  color: #532545;
  float: left;
  min-width: 100px;
  padding-right: 8px;
}
.circle-border-modal {
  height: 320px;
  width: 320px;
  border-radius: 50%;
  border: 10px solid #85d4dd;
  margin: auto;
  overflow: hidden;
}
.img-circle-modal {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
}
.modal-description-text {
  margin-bottom: 10px;
}
.labels_info {
  display: flex;
  flex-direction: row;
}
.labels_list {
  display: flex;
  flex-direction: column;
}
.img_carousel {
  height: 400px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2%;
  border: 5px solid #87314d;
  user-select: none;
}
.slider_control {
  color: #f4b346;
  background-color: #87314d;
  padding: 5px;
  box-shadow: 0 2px 2px 0 #aaa, 2px 6px 5px 0 #aaa;
  border-radius: 50%;
}
.slider_control:hover {
  background-color: #af2e59;
}
.mr-2 {
  padding: 5px 10px 6px 10px;
  margin: 0 2px 8px 2px !important;
}
.mr-3 {
  margin: 0 5px !important;
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 1200px) {
  .modal-header-left {
    width: 100%;
    border-right: none;
  }
  .modal-header-right {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
}
@media only screen and (max-width: 995px) {
  .modal-header-right {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }
  .modal-header-left {
    width: 100%;
    border-right: none;
  }
  .text-header-left {
    text-align: center;
  }
  .img_carousel {
    max-width: 300px;
    max-height: 300px;
  }
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 500px) {
  .slider_control {
    display: none;
  }
}
</style>
