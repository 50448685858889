<template>
  <div id="app">
    <top-header />
    <neko-picture />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <div
      id="to_top_button"
      @click="scrollToTop"
      data-aos="fade-left"
      data-aos-anchor-placement="bottom-center"
    >
      <b-icon
        icon="chevron-up"
        font-scale="1.7"
        class="m-auto"
        style="color: #f4b346; transform: translateY(-1px)"
      ></b-icon>
    </div>
    <top-footer />
    <chat-bot v-if="false"></chat-bot>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader.vue";
import Footer from "@/components/Footer.vue";
import ChatBot from "@/components/ChatBot.vue";
import NekoPicture from "@/components/NekoPicture.vue";

export default {
  title: "NekoLogic",
  components: {
    "top-header": TopHeader,
    "top-footer": Footer,
    "neko-picture": NekoPicture,
    "chat-bot": ChatBot,
  },
  created() {
    this.handleLanguage();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleLanguage() {
      if (navigator.language.substring(0, 2) === "fr") {
        this.$i18n.locale = "fr";
      } else if (navigator.language.substring(0, 2) === "en") {
        this.$i18n.locale = "en";
      } else if (navigator.language.substring(0, 2) === "ja") {
        this.$i18n.locale = "jp";
      } else {
        this.$i18n.locale = "en";
      }
    },
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #93dce6;
  //#a1dae4
}
#to_top_button {
  position: fixed;
  cursor: pointer;
  z-index: 20;
  bottom: 20px;
  right: 86px;
  display: flex;
  height: 56px;
  width: 56px;
  background-color: #87314d;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50%;
}
.fade-enter,
.fade-leave-to {
  transition: translateX(3em);
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s ease;
}
.h4,
h4 {
  font-size: 1.6rem;
}
@media only screen and (max-width: 540px) {
  #to_top_button {
    right: 25px;
  }
}
</style>
