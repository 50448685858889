import "@babel/polyfill";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {BootstrapVueIcons} from "bootstrap-vue";
import i18n from "./i18n";
import AOS from "aos";
import "aos/dist/aos.css";
import VueGtag from "vue-gtag";
import "leaflet/dist/leaflet.css";
import {Icon} from "leaflet";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.use(BootstrapVueIcons);
Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {id: "G-61MTM82QGC"},
});

new Vue({
  created() {
    AOS.init();
  },
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
