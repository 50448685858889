<template>
  <div
    class="card-shadow project-card"
    data-aos="fade-up"
    @click="modalShow = !modalShow"
    :id="getProjectID"
  >
    <div class="circle_border">
      <div
        class="img-circle"
        :style="{'background-image': 'url(' + `${getImage}` + ')'}"
      ></div>
    </div>
    <div class="info_panel">
      <div class="info_title">
        <h4>
          <strong> {{ getProjectName }} </strong>
        </h4>
      </div>
      <div class="info_icons">
        <span
          v-for="(icon, index) in getProjectPlatformId"
          :key="index"
          style="position: relative"
        >
          <span :data-tooltip="getProjectPlatform[index]" class="incon_tooltip">
            <img
              class="neko_icon"
              v-if="getIcon(icon) !== ''"
              :src="getIcon(icon)"
            />
          </span>
        </span>
      </div>
      <p class="info_description">{{ getProjectDescription }}</p>
      <b-modal
        v-if="true"
        size="xl"
        hide-header
        hide-footer
        v-model="modalShow"
      >
        <div style="display: flex; flex-direction: row-reverse">
          <b-icon
            class="h3"
            icon="x"
            style="cursor: pointer"
            @click="modalShow = !modalShow"
          ></b-icon>
        </div>
        <project-modal :id="projectData.id" :platformData="platformData" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import ProjectModal from "@/components/ProjectModal.vue";
export default {
  components: {
    "project-modal": ProjectModal,
  },
  props: [
    "name",
    "description",
    "image",
    "platform",
    "projectData",
    "platformData",
  ],
  data() {
    return {
      modalShow: (this.modalShow = this.$route.hash === "#" + this.projectID()),
      icons: {
        Android: 1,
        iOS: 2,
        AR: 3,
        MR: 4,
        VR: 5,
        WEB: 6,
        Audio: 7,
        Locational: 8,
        Switch: 9,
        Game: 10,
        Installation: 11,
        Hardware: 12,
        Consulation: 13,
        Backend: 14,
      },
    };
  },
  computed: {
    getImage() {
      let img = this.image;
      if (this.image === null || this.image === "") {
        img = require("../assets/default_img.png");
      }
      return img;
    },
    getProjectDescription() {
      if (this.projectData === undefined) return "";
      //had to do this due ot lack of API support for spanish at the moment, just change back to const lang = this.$i18n.locale.toUpperCase() when spanish is implemented
      const lang =
        this.$i18n.locale.toUpperCase() === "ES"
          ? "FR"
          : this.$i18n.locale.toUpperCase();
      if (
        this.projectData.project_description_locale === null ||
        this.projectData.project_description_locale === undefined
      )
        return this.description;
      if (lang === "EN") return this.description;
      return this.projectData.project_description_locale[lang];
    },
    getProjectID() {
      return this.projectID();
    },
    getProjectName() {
      if (this.projectData === undefined) return "";
      //had to do this due ot lack of API support for spanish at the moment, just change back to const lang = this.$i18n.locale.toUpperCase() when spanish is implemented
      const lang =
        this.$i18n.locale.toUpperCase() === "ES"
          ? "FR"
          : this.$i18n.locale.toUpperCase();
      if (
        this.projectData.project_name_locale === null ||
        this.projectData.project_name_locale === undefined
      )
        return this.name;
      if (lang === "EN") return this.name;
      return this.projectData.project_name_locale[lang];
    },
    getProjectPlatform() {
      if (this.platformData === undefined) return "";
      //had to do this due ot lack of API support for spanish at the moment, just change back to const lang = this.$i18n.locale.toUpperCase() when spanish is implemented
      const lang =
        this.$i18n.locale.toUpperCase() === "ES"
          ? "FR"
          : this.$i18n.locale.toUpperCase();
      if (
        this.projectData.project_platform_id === null ||
        this.projectData.project_platform_id === undefined ||
        this.projectData.project_platform_id.length === 0
      ) {
        console.log(JSON.stringify(this.projectData));
        return this.platform;
      }
      let value = [];
      this.projectData.project_platform_id.forEach((item) => {
        this.platformData.forEach((key) => {
          if (key.id !== item) return;
          value.push(key.platform_name_locale[lang]);
        });
      });

      return value;
    },
    getProjectPlatformId() {
      return this.projectData.project_platform_id;
    },
  },
  methods: {
    projectID() {
      if (this.projectData === undefined) return "";
      let fixedName = "" + this.name;
      fixedName = fixedName.toLowerCase();
      const regex = new RegExp(" ", "g");
      fixedName = fixedName.replace(regex, "-");
      return fixedName;
    },
    getIcon(icon) {
      switch (icon) {
        case this.icons.Android:
          icon = require("../assets/icons-projects/Android.svg");
          break;
        case this.icons.iOS:
          icon = require("../assets/icons-projects/iOS.svg");
          break;
        case this.icons.AR:
          icon = require("../assets/icons-projects/AR.svg");
          break;
        case this.icons.MR:
          icon = require("../assets/icons-projects/MR.svg");
          break;
        case this.icons.VR:
          icon = require("../assets/icons-projects/VR.svg");
          break;
        case this.icons.WEB:
          icon = require("../assets/icons-projects/Web.svg");
          break;
        case this.icons.Audio:
          icon = require("../assets/icons-projects/Audio.svg");
          break;
        case this.icons.Locational:
          icon = require("../assets/icons-projects/Locational.svg");
          break;
        case this.icons.Switch:
          icon = require("../assets/icons-projects/Switch.svg");
          break;
        case this.icons.Game:
          icon = require("../assets/icons-projects/Game.svg");
          break;
        case this.icons.Installation:
          icon = require("../assets/icons-projects/Installation.svg");
          break;
        case this.icons.Hardware:
          icon = require("../assets/icons-projects/Hardware.svg");
          break;
        case this.icons.Consulation:
          icon = require("../assets/icons-projects/Consultation.svg");
          break;
        case this.icons.Backend:
          icon = require("../assets/icons-projects/Backend.svg");
          break;
        default:
          icon = "";
      }
      return icon;
    },
  },
};
</script>

<style lang="scss" scoped>
.project-card {
  border-radius: 5px;
  background-color: white;
  width: 350px;
  height: 590px;
  transition: ease-in-out 0.25s;
  cursor: pointer;
  padding: 30px;
  text-align: center;
}

.project-card:hover {
  transform: translateY(-10px) !important;
}
.circle_border {
  height: 280px;
  width: 280px;
  border-radius: 50%;
  border: solid 10px #85d4dd;
  margin: auto;
  overflow: hidden;
}
.img-circle {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  transition: ease-in-out 0.8s;
}
.project-card:hover .img-circle {
  transform: scale(1.1);
}
.card-shadow {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
h4 {
  margin: 0; /* h4 should not be global! This is a temporary fix */
  /*  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden; */
}
.info_panel {
  padding-top: 20px;
  height: 300px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.info_title {
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.info_description {
  margin-bottom: 0;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to display */
  -webkit-box-orient: vertical;
}
.neko_icon {
  height: 30px;
  width: 30px;
  margin-right: 16px;
}
.info_icons > *:last-child .neko_icon {
  margin-right: 0px;
}
.info_icons > *:last-child .incon_tooltip::before {
  left: 50%;
}
.incon_tooltip::before,
.incon_tooltip::after {
  --scale: 0;
  position: absolute;
  top: -0.4rem;
  left: calc(50% - 8px);
  transform: translateX(-50%) translateY(-20px) scale(var(--scale));
  transition: ease-in-out 100ms transform;
  transform-origin: bottom center;
}
.incon_tooltip::before {
  content: attr(data-tooltip);
  padding: 2px 6px;
  font-size: 12px;
  width: max-content;
  border-radius: 5px;
  text-align: center;
  background: #87314d;
  color: white;
}
.incon_tooltip:hover::before {
  --scale: 1;
  content: attr(data-tooltip);
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 500px) {
  .project-card {
    height: 600px;
  }
  .info_panel {
    height: 285px;
  }
}

@media only screen and (max-width: 370px) {
  .project-card {
    width: 300px;
    height: 550px;
  }
}
@media only screen and (max-width: 330px) {
  .project-card {
    width: 300px;
    height: 570px;
  }
  .circle_border {
    height: 250px;
    width: 250px;
  }
}
</style>
