import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import options from "../options";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/works",
    name: "Works",
    component: () => import("../views/Works.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/commercial-disclosure",
    name: "CommercialDisclosure",
    component: () => import("../views/CommercialDisclosure.vue"),
  },
  {
    path: "/career",
    name: "Career",
    component: () => import("../views/Career.vue"),
  },
];

const router = new VueRouter({
  ...options.routerhistory,
  base: options.routerBase,
  routes,
});

export default router;
