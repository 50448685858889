<template>
  <div class="centralePicture my-4">
    <b-img
      id="nekopic"
      class="pic"
      :src="require('../assets/nekologic-logo-full.webp')"
      fluid
      center
      alt="Responsive image"
    ></b-img>
  </div>
</template>

<script>
export default {
  mounted() {
    document.getElementById("nekopic").addEventListener(
      "load",
      function () {
        document.getElementById("nekopic").style.opacity = "1";
      },
      false
    );
  },
};
</script>

<style scoped>
.centralePicture {
  width: 100%;
  min-height: 385px;
}
.pic {
  transition: opacity 350ms ease-in-out;
  opacity: 0;
  width: 50%;
}
@media only screen and (max-width: 820px) {
  .pic {
    width: 80%;
    transition: opacity 250ms ease-in-out;
  }
  .centralePicture {
    width: 100%;
    min-height: 100px;
  }
}
</style>
