<template>
  <df-messenger
    :chat-icon="require('../assets/dialogicon.png')"
    intent="WELCOME"
    chat-title="Ada-bot"
    agent-id="f4fcf6c2-198a-4407-aa26-fb520204bef9"
    :language-code="'en'"
  ></df-messenger>
</template>

<script>
export default {
  mounted() {
    var self = this;
    const dfMessenger = document.querySelector("df-messenger");
    dfMessenger.addEventListener("df-button-clicked", function (event) {
      if (
        event.detail.element.text === "See more AR projects" ||
        event.detail.element.text === "Voir plus de projets réalisés en AR"
      ) {
        self.goToProjects("ar");
      } else if (
        event.detail.element.text === "See more VR projects" ||
        event.detail.element.text === "Voir plus de projets réalisés en VR"
      ) {
        self.goToProjects("vr");
      } else if (event.detail.element.text === "See more web projects") {
        self.goToProjects("web");
      } else if (event.detail.element.text === "See more games") {
        self.goToProjects("game");
      } else if (event.detail.element.text === "See more installations") {
        self.goToProjects("installation");
      } else if (event.detail.element.text === "See audio projects") {
        self.goToProjects("audio");
      } else if (event.detail.element.text === "See locational projects") {
        self.goToProjects("locational");
      }
    });
    dfMessenger.addEventListener("df-response-received", function (event) {
      if (event.detail.response.queryResult.parameters.email !== undefined) {
        console.log(event.detail.response.queryResult.parameters.email);
      }
    });
  },
  computed: {
    handleLanguage() {
      if (this.$i18n.locale === "fr") {
        return "fr";
      } else if (this.$i18n.locale === "ja") {
        return "ja";
      } else {
        return "en";
      }
    },
  },
  methods: {
    goToProjects(filter) {
      this.$store.dispatch("setfilter", filter);
      this.$router.push({path: "/works"}).catch(() => {});
    },
  },
};
</script>

<style scoped>
df-messenger {
  --df-messenger-bot-message: #878fac;
  --df-messenger-button-titlebar-color: #87314d;
  --df-messenger-chat-background-color: #fafafa;
  --df-messenger-font-color: white;
  --df-messenger-send-icon: #f4b346;
  --df-messenger-user-message: #85d4dd;
  --df-messenger-minimized-chat-close-icon-color: #f4b346;
  --df-messenger-button-titlebar-font-color: #f4b346;
}
</style>
